import defaultIcon from "./defaultIcon";

const getGuildIcon = (guild, size) => {
  if (!size) {
    size = 512;
  }

  if (guild.icon)
    return `https://cdn.discordapp.com/icons/${guild?.id}/${guild?.icon}?size=${size}`;
  else return `${defaultIcon}?size=${size}`;
}

export default getGuildIcon;

import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import defaultIcon from "../../utils/defaultIcon";
import { BACKEND_URL } from "../../api/urls/endpoints";

/**
 * @param {string} iconUrl
 * @param {string} serverName
 * @param {number} membersCount
 * @param {string} inviteChar
 */
const TrustServer = ({ server }) => {
  const { t } = useTranslation();

  return (
    <a
      href={`${BACKEND_URL}/partners/${server.invite}`}
      target="_blank"
      rel="noreferrer"
      className="text-decoration-none"
    >
      <Row className="mx-3">
        <Col xs="auto" className="d-flex align-items-center px-0">
          <img
            width="48px"
            src={server.icon}
            alt={server.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `${defaultIcon}?size=512`;
            }}
            className="rounded"
          />
        </Col>
        <Col className="m-0">
          <h6 className="text-white text-decoration-none fs-bolder">
            {server.name}
          </h6>
          <p className="m-0 text-secondary-emphasis fw-semibold">{`${server.members.toLocaleString()} ${t(
            "home.members"
          )}`}</p>
        </Col>
      </Row>
    </a>
  );
};

export default TrustServer;

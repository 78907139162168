const CommandType = {
    All: 'All',
    Moderation: 'Moderation',
    Giveaway: 'Giveaway',
    LoggingSystem: 'LoggingSystem',
    PollSystem: 'PollSystem',
    FourFun: '4Fun',
    Economy: 'Economy',
    Other: 'Other'
}

export default CommandType;
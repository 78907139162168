import React from "react";
import { Col, Row } from "react-bootstrap";
import "../../App.css";

const FeatureComponent = ({ icon, title, description, alignRight }) => {
  return (
    <Row
      className={`feature align-items-center mt-5 mb-5 pb-5 ${
        alignRight ? "flex-row-reverse" : ""
      }`}
    >
      <Col xs={12} md={6} className="text-center">
        <h1>{icon}</h1>
      </Col>
      <Col xs={12} md={6}>
        <div className="text">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </Col>
    </Row>
  );
};

export default FeatureComponent;

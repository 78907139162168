import React, { useState, useRef, useEffect } from "react";
import { Button, Card, Form, Collapse, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AlertVariables from "../alerts/AlertVariables";
import useToast from "../../hooks/useToast";
import Status from "../../enums/Status";
import {
  deleteGoodbye,
  deleteWelcome,
  getGoodbye,
  getTextChannels,
  getWelcome,
  patchGoodbye,
  patchWelcome,
} from "../../api";
import LoadingSpinner from "../spinners/LoadingSpinner";
import useConfirmationModal from "../../hooks/useConfirmationModal";
const DEFAULT_COLOR = "#479aff";

const Welcome = ({ guildId }) => {
  const goodbyeFormRef = useRef(null);
  const welcomeFormRef = useRef(null);
  const [textChannels, setTextChannels] = useState(null);
  const [goodbyeMessage, setGoodbyeMessage] = useState(null);
  const [welcomeMessage, setWelcomeMessage] = useState(null);

  const [openGoodbyeTitle, setOpenGoodbyeTitle] = useState(false);
  const [openGoodbyeDescription, setOpenGoodbyeDescription] = useState(false);
  const [openWelcomeTitle, setOpenWelcomeTitle] = useState(false);
  const [openWelcomeDescription, setOpenWelcomeDescription] = useState(false);

  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showModal } = useConfirmationModal();

  const onGoodbyeSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    changeGoodbye(formDataObj);
  };

  const onWelcomeSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    changeWelcome(formDataObj);
  };

  const changeGoodbye = async (goodbyeParam) => {
    try {
      const data = await patchGoodbye(goodbyeParam, guildId);
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
      } else {
        showToast("Error saving goodbye message", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg || "Error saving goodbye message",
        Status.Fail
      );
    }
  };

  const changeWelcome = async (welcomeParam) => {
    try {
      if (welcomeParam?.image) {
        welcomeParam.image = true;
      } else {
        welcomeParam.image = false;
      }

      const data = await patchWelcome(welcomeParam, guildId);
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
      } else {
        showToast("Error saving welcome message", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg || "Error saving welcome message",
        Status.Fail
      );
    }
  };

  const deleteGuildGoodbye = async () => {
    try {
      const data = await deleteGoodbye(guildId);
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
      } else {
        showToast("Error deleting goodbye message", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg || "Error deleting goodbye message",
        Status.Fail
      );
    }
  };

  const deleteGuildWelcome = async () => {
    try {
      const data = await deleteWelcome(guildId);
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
      } else {
        showToast("Error deleting welcome message", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg || "Error deleting welcome message",
        Status.Fail
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const defaultObject = {
        color: DEFAULT_COLOR,
      };

      const reqGoodbye = (await getGoodbye(guildId)) ?? defaultObject;
      setGoodbyeMessage(reqGoodbye);

      const reqWelcome = (await getWelcome(guildId)) ?? defaultObject;
      setWelcomeMessage(reqWelcome);

      const channels = await getTextChannels(guildId);
      setTextChannels(channels);
    };
    fetchData();
  }, [guildId]);

  const handleUpdateWelcome = (param, value) => {
    setWelcomeMessage((prev) => ({ ...prev, [param]: value }));
  };

  const handleUpdateGoodbye = (param, value) => {
    setGoodbyeMessage((prev) => ({ ...prev, [param]: value }));
  };

  if (!welcomeMessage || !goodbyeMessage || !textChannels)
    return <LoadingSpinner />;

  return (
    <>
      <Row>
        <Col md={12} xl={6}>
          <Card className="m-2 bg-dark-subtle" key="welcomeCard">
            <Card.Header className="bg-primary">
              <Card.Title className="m-0">
                {t("dashboard.welcomeAndGoodbye.welcome.header")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <>
                <Form onSubmit={onWelcomeSubmit} ref={welcomeFormRef}>
                  <Form.Group className="mb-3" controlId="welcomeTitle">
                    <Form.Label>
                      {t("dashboard.welcomeAndGoodbye.titleInput")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter title"
                      name="title"
                      value={welcomeMessage.title}
                      onChange={(e) =>
                        handleUpdateWelcome("title", e.target.value)
                      }
                      maxLength="200"
                      onFocus={() => setOpenWelcomeTitle(true)}
                      onBlur={() => setOpenWelcomeTitle(false)}
                    />
                  </Form.Group>
                  <Collapse in={openWelcomeTitle} dimension="height">
                    <div className="mt-3">
                      <AlertVariables />
                    </div>
                  </Collapse>

                  <Form.Group className="mb-3" controlId="welcomeDescription">
                    <Form.Label>
                      {t("dashboard.welcomeAndGoodbye.descriptionInput")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      type="text"
                      placeholder="Enter description"
                      name="description"
                      maxLength="1000"
                      value={welcomeMessage.description}
                      onChange={(e) =>
                        handleUpdateWelcome("description", e.target.value)
                      }
                      onFocus={() => setOpenWelcomeDescription(true)}
                      onBlur={() => setOpenWelcomeDescription(false)}
                    />
                  </Form.Group>
                  <Collapse in={openWelcomeDescription} dimension="height">
                    <div className="mt-3">
                      <AlertVariables withMention />
                    </div>
                  </Collapse>

                  <Form.Group className="mb-3" controlId="welcomeChannel">
                    <Form.Label>
                      {t("dashboard.welcomeAndGoodbye.channelInput")}
                    </Form.Label>
                    <Form.Select
                      aria-label="Select channel"
                      name="channel_id"
                      disabled={textChannels && textChannels?.length === 0}
                      value={welcomeMessage.channel_id}
                      onChange={(e) =>
                        handleUpdateWelcome("channel_id", e.target.value)
                      }
                    >
                      {textChannels &&
                        textChannels.map((channel) => (
                          <option key={channel.id} value={channel.id}>
                            {channel.name}
                          </option>
                        ))}

                      {textChannels && textChannels?.length === 0 && (
                        <option key="GoodbyeChannel">
                          {t("dashboard.noChannels")}
                        </option>
                      )}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="welcomeImage">
                    <Form.Label>
                      {t("dashboard.welcomeAndGoodbye.imageInput")}
                    </Form.Label>
                    <Form.Check
                      type="switch"
                      name="image"
                      id="welcomeImage"
                      checked={welcomeMessage.image}
                      onChange={(e) =>
                        handleUpdateWelcome("image", e.target.checked)
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="welcomeColor">
                    <Form.Label>
                      {t("dashboard.welcomeAndGoodbye.colorInput")}
                    </Form.Label>
                    <Form.Control
                      type="color"
                      name="color"
                      title="Choose color"
                      className="w-100"
                      value={welcomeMessage.color}
                      onChange={(e) =>
                        handleUpdateWelcome("color", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    name="saveButton"
                    className="me-2"
                    disabled={
                      (textChannels && textChannels?.length === 0) ||
                      (welcomeFormRef.current &&
                        !welcomeFormRef.current.title.value)
                    }
                  >
                    {t("dashboard.save")}
                  </Button>
                  <Button
                    variant="danger"
                    disabled={goodbyeMessage === null}
                    onClick={() => showModal(deleteGuildWelcome)}
                  >
                    {t("dashboard.delete")}
                  </Button>
                </Form>
              </>
            </Card.Body>
          </Card>
        </Col>

        <Col md={12} xl={6}>
          <Card className="m-2 bg-dark-subtle" key="goodbyeCard">
            <Card.Header className="bg-primary">
              <Card.Title className="m-0">
                {t("dashboard.welcomeAndGoodbye.goodbye.header")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <>
                <Form onSubmit={onGoodbyeSubmit} ref={goodbyeFormRef}>
                  <Form.Group className="mb-3" controlId="goodbyeTitle">
                    <Form.Label>
                      {t("dashboard.welcomeAndGoodbye.titleInput")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter title"
                      name="title"
                      value={goodbyeMessage.title}
                      onChange={(e) =>
                        handleUpdateGoodbye("title", e.target.value)
                      }
                      maxLength="200"
                      onFocus={() => setOpenGoodbyeTitle(true)}
                      onBlur={() => setOpenGoodbyeTitle(false)}
                    />
                  </Form.Group>
                  <Collapse in={openGoodbyeTitle} dimension="height">
                    <div className="mt-3">
                      <AlertVariables />
                    </div>
                  </Collapse>

                  <Form.Group className="mb-3" controlId="goodbyeDescription">
                    <Form.Label>
                      {t("dashboard.welcomeAndGoodbye.descriptionInput")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      type="text"
                      placeholder="Enter description"
                      name="description"
                      maxLength="1000"
                      value={goodbyeMessage.description}
                      onChange={(e) =>
                        handleUpdateGoodbye("description", e.target.value)
                      }
                      onFocus={() => setOpenGoodbyeDescription(true)}
                      onBlur={() => setOpenGoodbyeDescription(false)}
                    />
                  </Form.Group>
                  <Collapse in={openGoodbyeDescription} dimension="height">
                    <div className="mt-3">
                      <AlertVariables withMention />
                    </div>
                  </Collapse>

                  <Form.Group className="mb-3" controlId="goodbyeChannel">
                    <Form.Label>
                      {t("dashboard.welcomeAndGoodbye.channelInput")}
                    </Form.Label>
                    <Form.Select
                      aria-label="Select channel"
                      name="channel_id"
                      disabled={textChannels && textChannels?.length === 0}
                      value={goodbyeMessage.channel_id}
                      onChange={(e) =>
                        handleUpdateGoodbye("channel_id", e.target.value)
                      }
                    >
                      {textChannels &&
                        textChannels.map((channel) => (
                          <option key={channel.id} value={channel.id}>
                            {channel.name}
                          </option>
                        ))}

                      {textChannels && textChannels?.length === 0 && (
                        <option key="GoodbyeChannel">
                          {t("dashboard.noChannels")}
                        </option>
                      )}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="goodbyeColor">
                    <Form.Label>
                      {t("dashboard.welcomeAndGoodbye.colorInput")}
                    </Form.Label>
                    <Form.Control
                      type="color"
                      name="color"
                      title="Choose color"
                      className="w-100"
                      value={goodbyeMessage.color}
                      onChange={(e) =>
                        handleUpdateGoodbye("color", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    name="saveButton"
                    className="me-2"
                    disabled={
                      (textChannels && textChannels?.length === 0) ||
                      (goodbyeFormRef.current &&
                        !goodbyeFormRef.current.title.value)
                    }
                  >
                    {t("dashboard.save")}
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => showModal(deleteGuildGoodbye)}
                  >
                    {t("dashboard.delete")}
                  </Button>
                </Form>
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Welcome;

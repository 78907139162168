import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import truncateText from "../../utils/truncateText";
import CurrencyIcon from "./CurrencyIcon";

const StoreItem = ({ item, currencyIconEmojiId, showItemModal }) => {
  return (
    <ListGroup.Item
      action
      onClick={() => showItemModal(item)}
      key={`item-${item.id}`}
      variant="dark"
    >
      <Row>
        <Col
          md={3}
          xs="auto"
          className="d-flex align-items-center justify-content-start"
        >
          <span>
            <strong>{item.name}</strong>
          </span>
        </Col>
        <Col
          md={6}
          xs="auto"
          className="d-flex align-items-center justify-content-start"
        >
          <span>
            <em>{truncateText(item.description, 50)}</em>
          </span>
        </Col>
        <Col
          md={3}
          xs="auto"
          className="d-flex align-items-center justify-content-end"
        >
          <span>
            {item.cost.toLocaleString()}
            <CurrencyIcon currencyIcon={currencyIconEmojiId} />
          </span>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default StoreItem;

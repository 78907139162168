import api from "../../api";

export async function getItems(guildId) {
  const req = await api.get(`/guilds/${guildId}/economy/items`);
  return req.data;
}

/**
 *
 * @param {Item} item
 * @param {number} guildId
 * @returns
 */
export async function createItem(item, guildId) {
  item.stockRemaining = item.stockRemaining || null;
  const req = await api.post(`/guilds/${guildId}/economy/items`, {
    item: item,
  });
  return req.data;
}

/**
 *
 * @param {Item} item
 * @param {number} itemId
 * @param {number} guildId
 * @returns
 */
export async function putItem(item, itemId, guildId) {
  item.stockRemaining = item.stockRemaining || null;
  const req = await api.put(`/guilds/${guildId}/economy/items/${itemId}`, {
    item: item,
  });
  return req.data;
}

/**
 *
 * @param {number} itemId
 * @param {number} guildId
 * @returns
 */
export async function deleteItem(itemId, guildId) {
  const req = await api.delete(`/guilds/${guildId}/economy/items/${itemId}`);
  return req.data;
}

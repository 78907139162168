import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, ListGroup } from "react-bootstrap";

const AlertVariables = ({ withMention }) => {
  const { t } = useTranslation();

  return (
    <Alert variant="info" className="pb-2">
      <h6>{t("dashboard.welcomeAndGoodbye.variables")}</h6>
      <ListGroup variant="flush">
        {withMention && (
          <ListGroup.Item className="bg-transparent">{`<user> - ${t(
            "dashboard.welcomeAndGoodbye.userPing"
          )}`}</ListGroup.Item>
        )}
        <ListGroup.Item className="bg-transparent">{`<user.name> - ${t(
          "dashboard.welcomeAndGoodbye.userName"
        )}`}</ListGroup.Item>
        <ListGroup.Item className="bg-transparent">{`<server> - ${t(
          "dashboard.welcomeAndGoodbye.serverName"
        )}`}</ListGroup.Item>
        <ListGroup.Item className="bg-transparent">{`<membersCount> - ${t(
          "dashboard.welcomeAndGoodbye.membersCount"
        )}`}</ListGroup.Item>
      </ListGroup>
    </Alert>
  );
};

export default AlertVariables;

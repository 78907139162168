import React, { useState } from "react";
import WebsiteNavbar from "../components/navbar/Navbar";
import Footer from "../components/basic/Footer";
import {
  Container,
  Accordion,
  Button,
  Form,
  Row,
  Col,
  ButtonGroup,
  InputGroup,
  ButtonToolbar,
} from "react-bootstrap";
import CommandType from "../enums/CommandType";
import commands from "../data/commands.json";
import { useTranslation } from "react-i18next";
import Title from "../components/basic/Title";
import FadeIn from "../components/effects/FadeIn";
import CommandItem from "../components/commands/CommandItem";
import CommandsCategories from "../enums/CommandCategories";

export default function Commands() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState(CommandType.All);
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const isInAnyCategory = (commandName) => {
    return Object.values(CommandsCategories).some((commands) =>
      commands.includes(commandName.toLowerCase())
    );
  };

  const filteredCommands = commands
    .filter((command) => {
      const commandName =
        command?.name_localizations?.[language] || command.name;
      const nameMatch = commandName
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      if (searchTerm !== "" && selectedType !== CommandType.All)
        setSelectedType(CommandType.All);

      const categoryMath =
        selectedType === CommandType.All ||
        (selectedType === CommandType.Other &&
          !isInAnyCategory(command.name.toLowerCase())) ||
        CommandsCategories[selectedType]?.includes(command.name.toLowerCase());

      return nameMatch && categoryMath;
    })
    .sort((a, b) => {
      const commandAName = a?.name_localizations?.[language] || a.name;
      const commandBName = b?.name_localizations?.[language] || b.name;
      return commandAName.localeCompare(commandBName);
    });

  const handleTypeChange = (type) => {
    setSearchTerm("");
    setSelectedType(type);
  };

  return (
    <Title title={t("navbar.commands")}>
      <WebsiteNavbar />
      <Container className="mb-5 mt-2">
        <FadeIn>
          <h2>{t("commands.title")}</h2>
        </FadeIn>
        <Row className="mt-3">
          <Col>
            <ButtonToolbar
              className="justify-content-between"
              aria-label="Type Command Buttons"
            >
              <ButtonGroup
                aria-label="First group"
                className="rounded-0 d-sm-block d-none mb-2"
              >
                <Button
                  variant="outline-primary"
                  className="rounded border-0 btn"
                  onClick={() => handleTypeChange(CommandType.All)}
                  active={selectedType === CommandType.All}
                >
                  {t("commandTypes.all")}
                </Button>{" "}
                <Button
                  variant="outline-primary"
                  className="rounded border-0"
                  onClick={() => handleTypeChange(CommandType.Moderation)}
                  active={selectedType === CommandType.Moderation}
                >
                  {t("commandTypes.moderation")}
                </Button>{" "}
                <Button
                  variant="outline-primary"
                  className="rounded border-0"
                  onClick={() => handleTypeChange(CommandType.Giveaway)}
                  active={selectedType === CommandType.Giveaway}
                >
                  {t("commandTypes.giveaway")}
                </Button>{" "}
                <Button
                  variant="outline-primary"
                  className="rounded border-0"
                  onClick={() => handleTypeChange(CommandType.LoggingSystem)}
                  active={selectedType === CommandType.LoggingSystem}
                >
                  {t("commandTypes.loggingSystem")}
                </Button>{" "}
                <Button
                  variant="outline-primary"
                  className="rounded border-0"
                  onClick={() => handleTypeChange(CommandType.PollSystem)}
                  active={selectedType === CommandType.PollSystem}
                >
                  {t("commandTypes.polls")}
                </Button>{" "}
                <Button
                  variant="outline-primary"
                  className="rounded border-0"
                  onClick={() => handleTypeChange(CommandType.FourFun)}
                  active={selectedType === CommandType.FourFun}
                >
                  {t("commandTypes.4Fun")}
                </Button>{" "}
                <Button
                  variant="outline-primary"
                  className="rounded border-0"
                  onClick={() => handleTypeChange(CommandType.Economy)}
                  active={selectedType === CommandType.Economy}
                >
                  {t("commandTypes.economy")}
                </Button>{" "}
                <Button
                  variant="outline-primary"
                  className="rounded border-0"
                  onClick={() => handleTypeChange(CommandType.Other)}
                  active={selectedType === CommandType.Other}
                >
                  {t("commandTypes.other")}
                </Button>{" "}
              </ButtonGroup>
              <InputGroup className="mb-2">
                <Form.Control
                  type="text"
                  placeholder={t("commands.searchCommands")}
                  aria-label="Search Commands Input"
                  aria-describedby="btnGroupAddon"
                  value={searchTerm}
                  name="command-search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row className="my-4">
          <Accordion defaultActiveKey="0">
            {filteredCommands.map((command, index) => (
              <React.Fragment key={`command-${index}`}>
                {command?.options?.length > 0 &&
                (command?.options[0]?.type === 2 ||
                  command?.options[0]?.type === 1) ? (
                  command.options.map((option, indexGroup) => (
                    <React.Fragment key={`option-${indexGroup}`}>
                      {option?.type === 1 && (
                        <CommandItem
                          command={command}
                          optionGroup={indexGroup}
                          lang={language}
                        />
                      )}
                      {option?.type === 2 &&
                        option.options.map((subOption, index) => (
                          <React.Fragment
                            key={`sub-option-${indexGroup}-${index}`}
                          >
                            {subOption?.type === 1 && (
                              <CommandItem
                                command={command}
                                option={index}
                                optionGroup={indexGroup}
                                lang={language}
                              />
                            )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))
                ) : (
                  <CommandItem command={command} lang={language} />
                )}
              </React.Fragment>
            ))}
          </Accordion>
        </Row>
      </Container>
      <Footer />
    </Title>
  );
}

import api from "../../api";

export async function getProperties(guildId) {
  const req = await api.get(`/guilds/${guildId}/economy/properties`);
  return req.data;
}

export async function getCurrency(guildId) {
  const req = await api.get(`/guilds/${guildId}/economy/properties/currency`);
  return req.data;
}

export async function putCooldowns(cooldowns, guildId) {
  const req = await api.put(`/guilds/${guildId}/economy/properties/cooldowns`, {
    cooldowns,
  });
  return req.data;
}

export async function putMaxBets(maxBets, guildId) {
  const req = await api.put(`/guilds/${guildId}/economy/properties/maxBets`, {
    maxBets,
  });
  return req.data;
}

export async function putFailFeePercentages(failFeePercentages, guildId) {
  const req = await api.put(
    `/guilds/${guildId}/economy/properties/failFeePercentages`,
    {
      failFeePercentages,
    }
  );
  return req.data;
}

export async function putIncomes(incomes, guildId) {
  const req = await api.put(`/guilds/${guildId}/economy/properties/incomes`, {
    incomes,
  });
  return req.data;
}

export async function putSuccessChances(successChances, guildId) {
  const req = await api.put(
    `/guilds/${guildId}/economy/properties/successChances`,
    {
      successChances,
    }
  );
  return req.data;
}

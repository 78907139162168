import React from 'react';
import { Container } from 'react-bootstrap';
import WebsiteNavbar from '../components/navbar/Navbar';
import Footer from '../components/basic/Footer';
import Title from '../components/basic/Title';

export default function NotFound() {
    return (
        <Title title="Not Found">
            <WebsiteNavbar />
            <Container className='mt-5 d-flex align-items-center justify-content-center vh-70'>
                <h1>404 Not Found</h1>
            </Container>
            <Footer />
        </ Title>
    );
}
import React, { useEffect, useState } from 'react';

function FadeIn({ children }) {
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    function handleScroll() {
      const elements = document.querySelectorAll('.fade-in');
      elements.forEach(element => {
        if (isElementInViewport(element)) {
          element.classList.add('fade-in-active');
        } else {
          element.classList.remove('fade-in-active');
        }
      });
    }

    function isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 50 &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) + 50
      );
    }

    if (isInitialRender) {
      setIsInitialRender(false);
      handleScroll();
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isInitialRender]);

  return <div className="fade-in">{children}</div>;
}

export default FadeIn;
import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Container,
  Badge,
  Alert,
  Button,
} from "react-bootstrap";
import premiumLogTypes from "../../data/premiumLogTypes.json";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../spinners/LoadingSpinner";
import { getLogs, getPremium, getTextChannels, patchLogs } from "../../api";
import Status from "../../enums/Status";
import useToast from "../../hooks/useToast";

const Logs = ({ guildId }) => {
  const [premium, setPremium] = useState(null);
  const [logs, setLogs] = useState([]);
  const [textChannels, setTextChannels] = useState(null);
  const [changeLogs] = useState([]);
  const saveBaner = useRef(null);
  const { t } = useTranslation();
  const { showToast } = useToast();

  const checkSaveLogs = () => {
    if (!saveBaner.current) return;
    const classList = saveBaner.current.classList;
    if (classList.contains("d-none")) {
      classList.remove("d-none");
    }
    if (changeLogs.length > 0) {
      if (classList.contains("puffHideAnimation")) {
        classList.remove("puffHideAnimation");
      }
      if (!classList.contains("puffAnimation")) {
        classList.add("puffAnimation");
      }
    } else {
      if (classList.contains("puffAnimation")) {
        classList.remove("puffAnimation");
      }
      if (!classList.contains("puffHideAnimation")) {
        classList.add("puffHideAnimation");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const textChannels = await getTextChannels(guildId);
      setTextChannels(textChannels);
      const logs = await getLogs(guildId);
      setLogs(logs);
      const premium = await getPremium(guildId);
      setPremium(premium);
    };
    fetchData();
  }, [guildId]);

  const handleLogChange = (type, key, value) => {
    const index = changeLogs.findIndex((log) => log.type === type);
    if (index > -1) {
      const logDefault = logs.find((log) => log.type === type);
      if (
        logDefault[key] === value ||
        (key === "color" &&
          logDefault[key] != null &&
          intToHexColor(logDefault[key]) === value)
      ) {
        delete changeLogs[index][key];
        if (Object.keys(changeLogs[index]).length < 2)
          changeLogs.splice(index, 1);
      } else {
        changeLogs[index][key] = value;
      }
    } else {
      changeLogs.push({ type, [key]: value });
    }
    checkSaveLogs();
  };

  const handleEnabledLogChange = (type, checked) => {
    handleLogChange(type, "enabled", checked);
  };

  const handleChannelLogChange = (type, channelId) => {
    handleLogChange(type, "channel_id", channelId);
  };

  const handleColorLogChange = (type, color) => {
    handleLogChange(type, "color", color);
  };

  async function saveLogs() {
    const modificatedLogs = changeLogs.map((ele) => {
      const defaultLog = logs.find((log) => log.type === ele.type) || {};

      ele.enabled ??= defaultLog.enabled;

      if (!ele.channel_id) {
        ele.channel_id = defaultLog?.channel_id || textChannels?.[0]?.id;
      }

      if (ele.color) {
        ele.color = hexToInteger(ele.color);
      } else {
        if (defaultLog?.color) ele.color = defaultLog.color;
        else ele.color = hexToInteger("#479aff");
      }
      return ele;
    });

    try {
      console.log(modificatedLogs);
      const data = await patchLogs(modificatedLogs, guildId);
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
        const logsGet = await getLogs(guildId);
        setLogs(logsGet);
        changeLogs.splice(0);
        checkSaveLogs();
      } else {
        showToast("Error saving logs", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg || "Error saving logs",
        Status.Fail
      );
    }
  }

  function intToHexColor(intColor) {
    const hexColor = intColor.toString(16).padStart(6, "0");
    return `#${hexColor}`;
  }

  function hexToInteger(hexColor) {
    if (hexColor.startsWith("#")) {
      hexColor = hexColor.slice(1);
    }
    return parseInt(hexColor, 16);
  }

  if (premium === null || !logs || !textChannels) return <LoadingSpinner />;

  return (
    <>
      <Container fluid>
        <div className="d-flex flex-wrap justify-content-center">
          {logs.map((log, index) => (
            <Card key={log.type} className="m-2" style={{ width: "30rem" }}>
              <Card.Header className="bg-black">
                <Row>
                  {premiumLogTypes.includes(log.type) && (
                    <Col xs={12} className="d-flex justify-content-start mb-1">
                      <Badge>Premium</Badge>
                    </Col>
                  )}
                  <Col xs="9">
                    <Card.Title className="m-0">
                      {t(`dashboard.logging.types.${log.type}`)}
                    </Card.Title>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end">
                    <Form.Check
                      type="switch"
                      name={`log-switch-${log.type.toLowerCase()}`}
                      style={{
                        transform: "scale(1.5)",
                      }}
                      defaultChecked={log?.enabled}
                      onChange={(e) =>
                        handleEnabledLogChange(log.type, e.target.checked)
                      }
                      disabled={
                        (log.premium && !premium) ||
                        (textChannels && textChannels?.length === 0)
                      }
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="bg-dark-subtle">
                <>
                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor={`channel-log-${log.type.toLowerCase()}`}
                    >
                      {t("dashboard.logging.channel")}
                    </Form.Label>
                    <Form.Select
                      name="channel"
                      id={`channel-log-${log.type.toLowerCase()}`}
                      defaultValue={log?.channel_id}
                      onChange={(e) =>
                        handleChannelLogChange(log.type, e.target.value)
                      }
                      disabled={
                        (log.premium && !premium) ||
                        (textChannels && textChannels?.length === 0)
                      }
                    >
                      {textChannels &&
                        textChannels.map((channel) => (
                          <option key={channel.id} value={channel.id}>
                            {channel.name}
                          </option>
                        ))}
                      {textChannels && textChannels?.length === 0 && (
                        <option key={log.type}>
                          {t("dashboard.noChannels")}
                        </option>
                      )}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor={`color-log-${log.type.toLowerCase()}`}>
                      {t("dashboard.logging.color")}
                    </Form.Label>
                    <Form.Control
                      type="color"
                      name="color"
                      id={`color-log-${log.type.toLowerCase()}`}
                      defaultValue={
                        log?.color != null
                          ? intToHexColor(log?.color)
                          : "#479aff"
                      }
                      title="Choose color"
                      className="w-100"
                      onChange={(e) =>
                        handleColorLogChange(log.type, e.target.value)
                      }
                      disabled={
                        (log.premium && !premium) ||
                        (textChannels && textChannels?.length === 0)
                      }
                    />
                  </Form.Group>
                </>
              </Card.Body>
            </Card>
          ))}
        </div>
        <div className="d-none sticky-bottom p-2" ref={saveBaner}>
          <Alert variant="danger" className="p-3">
            <Row className="d-flex justify-content-between align-items-center">
              <Col xs="auto">{t("dashboard.unsavedChanges")}</Col>
              <Col xs={12} sm="auto" className="d-flex justify-content-center">
                <Button variant="primary" className="w-100" onClick={saveLogs}>
                  {t("dashboard.save")}
                </Button>
              </Col>
            </Row>
          </Alert>
        </div>
      </Container>
    </>
  );
};

export default Logs;

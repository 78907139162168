import React, { createContext, useState, useEffect } from "react";
import { getUser } from "../api";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  /**
   * @type {User}
   */
  const [user, setUser] = useState(false);
  /**
   * @type {boolean}
   */
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getUser();
        setUser(user);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user:", error);
        setUser(false);
        setLoading(false);
      }
    };

    if (!user) {
      fetchData();
    }
  }, [user]);

  /*const logout = async () => {
    try {
      await getLogout();
      setUser(false);
      setLoading(false);
    } catch (error) {
      setUser(false);
      setLoading(false);
      console.error("Error logging out:", error);
    }
  };*/

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

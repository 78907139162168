import { useContext } from "react";
import ConfirmationModalContext from "../context/ConfimationModalContext.js";

const useConfirmationModal = () => {
  /**
   * @type {{
   *  showModal: (onConfirm: void) => void;
   *  hideModal: () => void;
   * }}
   */
  const context = useContext(ConfirmationModalContext);

  if (!context) {
    throw new Error("useConfirmationModal must be used within a ConfirmationModalProvider");
  }

  return context;
};

export default useConfirmationModal;

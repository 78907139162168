const discordEmojiUrl = "https://cdn.discordapp.com/emojis/";

const CurrencyIcon = ({ currencyIcon }) => {
  return (
    <>
      &nbsp;
      {!isNaN(currencyIcon) ? (
        <img
          src={`${discordEmojiUrl}${currencyIcon}.webp`}
          width="auto"
          height="25px"
          alt="Currency Icon"
        />
      ) : (
        <b>{currencyIcon}</b>
      )}
    </>
  );
};

export default CurrencyIcon;

import { getI18n } from "react-i18next";
const i18n = getI18n();

export const calculateTimeLeft = (endTime) => {
  const difference = endTime - Date.now() / 1000;
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.round(difference / (60 * 60 * 24)),
      hours: Math.round((difference / (60 * 60)) % 24),
      minutes: Math.round((difference / 60) % 60),
      seconds: Math.round(difference % 60) ?? 0,
    };
  }

  return timeLeft;
};

export const formatTime = (timeLeft) => {
  const { days, hours, minutes, seconds } = timeLeft;
  if (days > 0) {
    return `${days} ${i18n.t("timeFormat.days")}`;
  } else if (hours > 0) {
    return `${hours} ${i18n.t("timeFormat.hours")}`;
  } else if (minutes) {
    return `${minutes} ${i18n.t("timeFormat.minutes")}`;
  } else {
    return `${seconds ?? 0} ${i18n.t("timeFormat.seconds")}`;
  }
};

import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useToast from "../../hooks/useToast";
import Status from "../../enums/Status";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { deleteUser, putUserMoney } from "../../api";

const EconomyUserModal = ({ user, guildId, show, hideModal, removeUser }) => {
  const { t } = useTranslation();
  const [userMoney, setUserMoney] = useState(null);
  const { showModal } = useConfirmationModal();
  const { showToast } = useToast();

  const handleSetUserMoney = (e) => setUserMoney(e.target.value);

  useEffect(() => {
    setUserMoney(user?.money);
  }, [user]);

  const updateEconomyUserMoney = async () => {
    try {
      const data = await putUserMoney(userMoney, user.id, guildId);
      if (data.status === "success") {
        user.money = userMoney;
        showToast(data.message, Status.Success);
        hideModal();
      } else {
        showToast("Error saving user money", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors[0]?.msg || "Error saving user money",
        Status.Fail
      );
    }
  };

  const deleteEconomyUser = async () => {
    try {
      const data = await deleteUser(user.id, guildId);
      if (data?.status === "success") {
        showToast(data.message, Status.Success);
        removeUser(user.id);
        hideModal();
      } else {
        showToast("Error deleting user", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors[0]?.msg || "Error deleting user",
        Status.Fail
      );
    }
  };

  if (!user) return;
  return (
    <>
      <Modal show={show} onHide={hideModal} size="lg" centered animation>
        <Modal.Body>
          <Row>
            <Col md={4} className="my-2">
              <img
                src={user.avatarUrl}
                alt={`Avatar ${user.username}`}
                className="rounded-circle"
                style={{
                  height: "5rem",
                  verticalAlign: "middle",
                }}
              />
            </Col>
            <Col
              md={12}
              className="d-flex align-items-center justify-content-space-around my-2 me-2"
            >
              <h4>{user.displayName}</h4>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="user.Money">
                <Form.Label>{t("dashboard.economyUsers.total")}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="1 000"
                  max={999999999}
                  min={-999999999}
                  value={userMoney}
                  onChange={handleSetUserMoney}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            {t("modal.close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => showModal(updateEconomyUserMoney)}
          >
            {t("modal.save")}
          </Button>
          <Button variant="danger" onClick={() => showModal(deleteEconomyUser)}>
            {t("modal.delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EconomyUserModal;

import api from "../api";

export async function patchWelcome(welcomeParam, guildId) {
  const req = await api.patch(`/guilds/${guildId}/welcome`, {
    welcome: welcomeParam,
  });
  return req.data;
}

export async function getWelcome(guildId) {
  const req = await api.get(`/guilds/${guildId}/welcome`);
  return req.data;
}

export async function deleteWelcome(guildId) {
  const req = await api.delete(`/guilds/${guildId}/welcome`);
  return req.data;
}

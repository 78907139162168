import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../spinners/LoadingSpinner";
import { getWarns } from "../../api";

const Warns = ({ guildId }) => {
  const [warns, setWarns] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const req = await getWarns(guildId);
      setWarns(req);
    };
    fetchData();
  }, [guildId]);

  if (!warns) return <LoadingSpinner />;

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ verticalAlign: "middle" }}>
              {t("dashboard.warns.user")}
            </th>
            <th>{t("dashboard.warns.warnings")}</th>
          </tr>
        </thead>
        <tbody>
          {warns?.length === 0 ? (
            <tr key={"noWarns"}>
              <td colSpan={2}>{t("dashboard.warns.noWarnings")}</td>
            </tr>
          ) : (
            warns.map((warn, index) => (
              <tr key={index}>
                <td>
                  <img
                    src={warn.avatarUrl}
                    alt={`Avatar ${warn.username}`}
                    className="rounded-circle"
                    style={{
                      height: "2rem",
                      verticalAlign: "middle",
                    }}
                  />
                  <span className="ms-2" style={{ verticalAlign: "middle" }}>
                    {warn.username}
                  </span>
                </td>
                <td style={{ verticalAlign: "middle" }}>{warn.warnCount}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Warns;

import Status from "../enums/Status";

/**
 * @param {Status} status
 */
const getStatusColor = (status) => {
  return status.toLowerCase() === Status.Success ? "success" : "danger";
};

export default getStatusColor;

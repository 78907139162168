import api from "../../api";

export async function getReplies(guildId) {
  const req = await api.get(`/guilds/${guildId}/economy/replies`);
  return req.data;
}

/**
 *
 * @param {Reply} reply
 * @param {number} replyId
 * @param {number} guildId
 * @returns
 */
export async function putReply(reply, replyId, guildId) {
  const req = await api.put(`/guilds/${guildId}/economy/replies/${replyId}`, {
    reply: reply,
  });
  return req.data;
}

/**
 *
 * @param {Reply} reply
 * @param {number} guildId
 * @returns
 */
export async function createReply(reply, guildId) {
  const req = await api.post(`/guilds/${guildId}/economy/replies`, {
    reply: reply,
  });
  return req.data;
}

export async function deleteReply(replyId, guildId) {
  const req = await api.delete(`/guilds/${guildId}/economy/replies/${replyId}`);
  return req.data;
}

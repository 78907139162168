import getGuildIcon from "./getGuildIcon";

const getGuildBanner = (guild, size) => {
  if (!size) {
    size = 1024;
  }

  if (guild.banner)
    return `https://cdn.discordapp.com/banners/${guild?.id}/${guild?.banner}?size=${size}`;
  else return getGuildIcon(guild, size);
};

export default getGuildBanner;

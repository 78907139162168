import React, { useEffect, useRef, useState } from "react";
import { ListGroup, Container, Badge } from "react-bootstrap";
import useUser from "../hooks/useUser";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DashboardNavbar from "../components/navbar/NavbarDashboard";

import autoroleIcon from "../assets/icons/AutoroleIcon.svg";
import generalIcon from "../assets/icons/GeneralIcon.svg";
import StoreIcon from "../assets/icons/StoreIcon.svg";
import UsersIcon from "../assets/icons/UsersIcon.svg";
import repliesIcon from "../assets/icons/RepliesIcon.svg";
import propertiesIcon from "../assets/icons/PropertiesIcon.svg";
import giveawaysIcon from "../assets/icons/GiveawaysIcon.svg";
import warnsIcon from "../assets/icons/WarnsIcon.svg";
import loggingIcon from "../assets/icons/LoggingIcon.svg";
import pollsIcon from "../assets/icons/PollsIcon.svg";
import welcomeIcon from "../assets/icons/WelcomeIcon.svg";

import NotFound from "./notfound";
import Title from "../components/basic/Title";

import General from "../components/dashboard/General";
import Welcome from "../components/dashboard/Welcome";
import Autorole from "../components/dashboard/Autorole";
import Logs from "../components/dashboard/Logs";
import Warns from "../components/dashboard/Warns";
import Giveaways from "../components/dashboard/Giveaways";
import Polls from "../components/dashboard/Polls";
import Properties from "../components/dashboard/economy/Properties";
import Users from "../components/dashboard/economy/Users";
import Store from "../components/dashboard/economy/Store";
import Replies from "../components/dashboard/economy/Replies";
import Options from "../enums/Options";

function DashboardServer() {
  const { guildId, option } = useParams();
  const { loading, user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const animationRef = useRef(null);
  const [showSidebar, setShowSidebar] = useState(true);
  const [mobileSidebar, setSidebarMobile] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const optionComponents = {
    [Options.General]: General,
    [Options.Welcome]: Welcome,
    [Options.Autorole]: Autorole,
    [Options.Logging]: Logs,
    [Options.Warns]: Warns,
    [Options.Giveaways]: Giveaways,
    [Options.Polls]: Polls,
    [Options.EconomyProperties]: Properties,
    [Options.EconomyUsers]: Users,
    [Options.EconomyStore]: Store,
    [Options.EconomyReplies]: Replies,
  };

  const OptionComponent = optionComponents[option];

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 768) {
      setShowSidebar(true);
      setSidebarMobile(false);
    } else {
      setShowSidebar(false);
      setSidebarMobile(true);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (OptionComponent) {
      const animationElement = animationRef.current;
      if (!animationElement) return;
      animationElement.classList.add("puffAnimation");

      const handleAnimationEnd = () => {
        animationElement.classList.remove("puffAnimation");
      };

      animationElement.addEventListener("animationend", handleAnimationEnd);

      return () => {
        animationElement.removeEventListener(
          "animationend",
          handleAnimationEnd
        );
      };
    }
  }, [OptionComponent]);

  if (loading) return;

  const guilds = user?.guilds;
  const guild = guilds?.find((guild) => guild.id === guildId);
  if (
    !user ||
    user === null ||
    !guilds.find((guild) => guild.id === guildId && guild.isNortBot)
  ) {
    navigate("/");
    return null;
  }

  if (!isOptionValid(option)) {
    return <NotFound />;
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const navigateAndCheckSlideBar = (path) => {
    navigate(path);
    checkHideMobileSidebar();
  };

  const checkHideMobileSidebar = () => {
    if (mobileSidebar && showSidebar) {
      setShowSidebar(false);
    }
  };

  return (
    <Title
      title={`${t(`dashboard.${option}.title`)} ${guild && `(${guild.name})`}`}
      noPadding
    >
      <DashboardNavbar
        guilds={guilds}
        currentGuildId={guildId}
        toggleSidebar={toggleSidebar}
      />
      <div
        className={!mobileSidebar ? "border-end" : ""}
        aria-orientation="vertical"
        style={{
          backgroundColor: "#212529",
          width: mobileSidebar && showSidebar ? "100vw" : "200px",
          height: "100%",
          position: "fixed",
          left: 0,
          transform: showSidebar ? "translateX(0)" : "translateX(-300px)",
          transition: "transform 0.6s, width 0.5s",
          zIndex: 999,
          overflowY: "auto",
        }}
      >
        <div className="nav flex-column pb-5">
          <strong className="my-3 ms-3">
            {t("dashboard.categories.settings")}
          </strong>
          <ListGroup className="rounded-0 border-start-0 bg-transparent">
            <ListGroup.Item
              action
              active={option === Options.General}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.General}`
                );
              }}
            >
              <img src={generalIcon} alt="General" className="me-2" />
              {t(`dashboard.${Options.General}.title`)}
            </ListGroup.Item>
            <ListGroup.Item
              action
              active={option === Options.Welcome}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.Welcome}`
                );
              }}
            >
              <img src={welcomeIcon} alt="Welcome" className="me-2" />
              {t(`dashboard.${Options.Welcome}.title`)}
            </ListGroup.Item>
          </ListGroup>
          <strong className="my-3 ms-3">
            {t("dashboard.categories.moderation")}
          </strong>
          <ListGroup className="rounded-0 border-start-0 bg-transparent">
            <ListGroup.Item
              action
              active={option === Options.Logging}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.Logging}`
                );
              }}
            >
              <img src={loggingIcon} alt="Logging" className="me-2" />
              {t(`dashboard.${Options.Logging}.title`)}
            </ListGroup.Item>
            <ListGroup.Item
              action
              active={option === Options.Autorole}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.Autorole}`
                );
              }}
            >
              <img src={autoroleIcon} alt="Autorole" className="me-2" />
              {t(`dashboard.${Options.Autorole}.title`)}
            </ListGroup.Item>
            <ListGroup.Item
              action
              active={option === Options.Warns}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.Warns}`
                );
              }}
            >
              <img src={warnsIcon} alt="Warns" className="me-2" />
              {t(`dashboard.${Options.Warns}.title`)}
            </ListGroup.Item>
          </ListGroup>
          <strong className="my-3 ms-3">
            {t("dashboard.categories.utility")}
          </strong>
          <ListGroup className="rounded-0 border-start-0 bg-transparent">
            <ListGroup.Item
              action
              active={option === Options.Giveaways}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.Giveaways}`
                );
              }}
            >
              <img src={giveawaysIcon} alt="Giveaways" className="me-2" />
              {t(`dashboard.${Options.Giveaways}.title`)}
            </ListGroup.Item>
            <ListGroup.Item
              action
              active={option === Options.Polls}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.Polls}`
                );
              }}
            >
              <img src={pollsIcon} alt="Polls" className="me-2" />
              {t(`dashboard.${Options.Polls}.title`)}
            </ListGroup.Item>
          </ListGroup>
          <strong className="my-3 ms-3">
            {t("dashboard.categories.economy")}
            <Badge bg="primary" className="ms-1">
              {t("new")}
            </Badge>
          </strong>
          <ListGroup className="rounded-0 border-start-0 bg-transparent pb-5">
            <ListGroup.Item
              action
              active={option === Options.EconomyProperties}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.EconomyProperties}`
                );
              }}
            >
              <img
                src={propertiesIcon}
                alt="Economy Properties"
                className="me-2"
              />
              {t(`dashboard.${Options.EconomyProperties}.title`)}
            </ListGroup.Item>
            <ListGroup.Item
              action
              active={option === Options.EconomyUsers}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.EconomyUsers}`
                );
              }}
            >
              <img src={UsersIcon} alt="Economy Users" className="me-2" />
              {t(`dashboard.${Options.EconomyUsers}.title`)}
            </ListGroup.Item>
            <ListGroup.Item
              action
              active={option === Options.EconomyStore}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.EconomyStore}`
                );
              }}
            >
              <img src={StoreIcon} alt="Economy Shop" className="me-2" />
              {t(`dashboard.${Options.EconomyStore}.title`)}
            </ListGroup.Item>
            <ListGroup.Item
              action
              active={option === Options.EconomyReplies}
              className="border-0 d-flex align-items-center"
              onClick={() => {
                navigateAndCheckSlideBar(
                  `/dashboard/${guildId}/${Options.EconomyReplies}`
                );
              }}
            >
              <img src={repliesIcon} alt="Economy Replies" className="me-2" />
              {t(`dashboard.${Options.EconomyReplies}.title`)}
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
      <div className="d-flex w-100">
        <Container
          className="pb-4 pt-3"
          fluid
          style={{
            transition: "margin-left 0.5s",
            marginLeft: showSidebar && !mobileSidebar ? "200px" : 0,
          }}
        >
          <h3>{t(`dashboard.${option}.title`)}</h3>
          <hr />
          {OptionComponent && (
            <div ref={animationRef}>
              <OptionComponent
                guildId={guildId}
                guild={guilds.find((guild) => guild.id === guildId)}
              />
            </div>
          )}
        </Container>
      </div>
    </Title>
  );
}

function isOptionValid(option) {
  return Object.values(Options).includes(option);
}

export default DashboardServer;

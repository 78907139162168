import React, { useState, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../spinners/LoadingSpinner.js";
import useToast from "../../hooks/useToast.js";
import Status from "../../enums/Status.js";
import { getAutorole, getRoles, patchAutorole } from "../../api/index.js";

const Autorole = ({ guildId }) => {
  const [selectedAutorole, setSelectedAutorole] = useState(null);
  const [roles, setRoles] = useState(null);
  const [autorole, setAutorole] = useState(false);
  const { t } = useTranslation();
  const { showToast } = useToast();

  const changeAutorole = async () => {
    try {
      const req = await patchAutorole(selectedAutorole, guildId);
      if (req.status === 200) {
        showToast(req.data.message, Status.Success);
      }
    } catch {
      showToast("Error saving autorole", Status.Fail);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const req = await getAutorole(guildId);
      setAutorole(req);
      setSelectedAutorole(req);

      const roles = await getRoles(guildId);
      setRoles(roles);
    };
    fetchData();
  }, [guildId]);

  if (autorole === false || !roles) return <LoadingSpinner />;

  return (
    <>
      <Card className="m-2 bg-dark-subtle">
        <Card.Header className="bg-primary">
          <Card.Title className="m-0">
            {t("dashboard.autorole.autorole.header")}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <>
            <Form.Group className="mb-3" controlId="autorole">
              <Form.Label>{t("dashboard.autorole.autorole.role")}</Form.Label>
              <Form.Select
                aria-label="Select channel"
                name="autorole"
                disabled={roles?.length === 0}
                value={selectedAutorole}
                onChange={(e) => setSelectedAutorole(e.target.value)}
              >
                <option name="none-roles" key="noneRole" value={0}>
                  {t("dashboard.autorole.autorole.none")}
                </option>
                {roles.map((role) => (
                  <option
                    name={`role-${role.id}`}
                    key={role.id}
                    value={role.id}
                  >
                    {role.name}
                  </option>
                ))}
                {roles?.length === 0 && (
                  <option name="no-roles">
                    {t("dashboard.autorole.autorole.noRoles")}
                  </option>
                )}
              </Form.Select>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={roles?.length === 0}
              onClick={changeAutorole}
            >
              {t("dashboard.save")}
            </Button>
          </>
        </Card.Body>
      </Card>
    </>
  );
};

export default Autorole;

import { useContext } from "react";
import ToastContext from "../context/ToastContext.js";

const useToast = () => {
  /**
   * @type {{
   *  showToast: (message: string, status: string) => void;
   * }}
   */
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return context;
};

export default useToast;

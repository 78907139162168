import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import "./App.css";
import Home from "./pages/home";
import Commands from "./pages/commands";
import Privacy from "./pages/privacy";
import TermsOfService from "./pages/termsOfService";
import NotFound from "./pages/notfound";
import "./i18n";
import AuthorizationError from "./pages/authorizationError";
import Dashboard from "./pages/dashboard";
import DashboardServer from "./pages/dashboardServer";
import DashboardRoute from "./pages/dashboardRoute";
import Giveaway from "./pages/giveaway";
import { UserProvider } from "./context/UserContext";
import { ToastProvider } from "./context/ToastContext";
import { ConfirmationModalProvider } from "./context/ConfimationModalContext";

function App() {
  return (
    <UserProvider>
      <ToastProvider>
        <ConfirmationModalProvider>
          <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/commands" element={<Commands />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route
                path="/authorization-error"
                element={<AuthorizationError />}
              />
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/dashboard/:guildId" element={<DashboardRoute />} />
              <Route
                path="/dashboard/:guildId/:option"
                element={<DashboardServer />}
              />
              <Route path="/giveaway" element={<Giveaway />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </ConfirmationModalProvider>
      </ToastProvider>
    </UserProvider>
  );
}

export default App;

import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ show, onHide, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(0,0,0,0.8)" }}
    >
      <Modal.Header>
        <Modal.Title>{t("confirmationModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("confirmationModal.description")}</p>
        <Row className="mb-2 mt-3">
          <Col md={6} className="d-flex justify-content-center my-2">
            <Button className="w-100" variant="danger" onClick={onHide} size="lg">
              {t("modal.no")}
            </Button>
          </Col>
          <Col md={6} className="d-flex justify-content-center my-2">
            <Button className="w-100" variant="success" onClick={onConfirm} size="lg">
              {t("modal.yes")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;

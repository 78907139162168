import defaultIcon from "./defaultIcon";

const getUserAvatar = (user, size) => {
  if (!size) {
    size = 512;
  }

  if (user.avatar) {
    return `https://cdn.discordapp.com/avatars/${user?.id}/${user?.avatar}?size=${size}`;
  } else {
    return `${defaultIcon}?size=${size}`;
  }
};

export default getUserAvatar;

import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Row, Col, Card, Form } from "react-bootstrap";
import { calculateTimeLeft, formatTime } from "../../utils/calculateTimeLeft";
import { useTranslation } from "react-i18next";

function PollChart({ chartData, chartParams }) {
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(chartParams.endTime)
  );
  const { t } = useTranslation();
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(chartParams.endTime));
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="chart-container">
      <h2 style={{ textAlign: "center" }}>
        <a
          href={chartParams.messageUrl}
          target="_blank"
          className="text-decoration-none"
          rel="noreferrer"
        >
          {chartParams.title}
        </a>
      </h2>
      <div className="d-flex justify-content-center px-3">
        <Pie
          data={chartData}
          options={{
            plugins: {
              title: {
                display: true,
                text: `${chartParams.membersCount} ${t(
                  "dashboard.polls.membersVoted"
                )}`,
              },
            },
          }}
          style={{
            maxWidth: "30rem",
            maxHeight: "30rem",
          }}
        />
      </div>
      {chartParams.endTime != null && chartParams.ended === false && (
        <div className="text-center mt-4">
          <h4>{t("dashboard.endsIn")}</h4>
          <h4>{formatTime(timeLeft)}</h4>
        </div>
      )}

      <div className="settings-container mb-4">
        <h4 className="mb-2">{t("dashboard.polls.settings")}</h4>
        <Card>
          <Card.Body className="py-1">
            <Row>
              <Col md={6} lg={3} className="my-2">
                <h6>{t("dashboard.polls.allowedChoices")}</h6>
                <p className="m-0">{chartParams.maxChoices}</p>
              </Col>
              <Col md={6} lg={3} className="my-2">
                <h6>{t("dashboard.polls.anonymousPoll")}</h6>
                <Form.Check
                  disabled
                  type="switch"
                  name="anonymous-poll"
                  checked={chartParams.isAnonymous}
                />
              </Col>
              <Col md={6} lg={3} className="my-2">
                <h6>{t("dashboard.polls.allowedRole")}</h6>
                <Form.Check
                  disabled
                  type="switch"
                  name="allowed-role"
                  checked={chartParams.allowedRole}
                />
              </Col>
              <Col md={6} lg={3} className="my-2">
                <h6>{t("dashboard.polls.pollId")}</h6>
                <p className="m-0">{chartParams.id}</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
export default PollChart;

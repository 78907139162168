const Options = {
  General: "general",
  Welcome: "welcomeAndGoodbye",
  Logging: "logging",
  Autorole: "autorole",
  Warns: "warns",
  EconomyProperties: "economyProperties",
  EconomyUsers: "economyUsers",
  EconomyStore: "economyStore",
  EconomyReplies: "economyReplies",
  Giveaways: "giveaways",
  Polls: "polls",
};

export default Options;

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const DashboardRoute = () => {
    const { guildId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/dashboard/${guildId}/general`);
    }, [guildId, navigate])

    return null;
};

export default DashboardRoute;
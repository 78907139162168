import React from 'react';
import { Container } from 'react-bootstrap';
import WebsiteNavbar from '../components/navbar/Navbar';
import Footer from '../components/basic/Footer';
import Title from '../components/basic/Title';
import { useTranslation } from 'react-i18next';

export default function TermsOfService() {
    const { t } = useTranslation();
    return (
        <Title title={t('termsOfService.header')}>
            <WebsiteNavbar />
            <Container className='mt-2'>
                <h1>{t('termsOfService.header')}</h1>
                <p>{t('termsOfService.headerP')}</p>

                <h2>{t('termsOfService.useOfTheBot')}</h2>
                <p>{t('termsOfService.useOfTheBotP')}</p>

                <h2>{t('termsOfService.userConduct')}</h2>
                <p>{t('termsOfService.userConductP')}</p>

                <h2>{t('termsOfService.modificationsToTheBot')}</h2>
                <p>{t('termsOfService.modificationsToTheBotP')}</p>

                <h2>{t('termsOfService.limitationOfLiability')}</h2>
                <p>{t('termsOfService.limitationOfLiabilityP')}</p>

                <h2>{t('termsOfService.termination')}</h2>
                <p>{t('termsOfService.terminationP')}</p>

                <h2>{t('termsOfService.governingLaw')}</h2>
                <p>{t('termsOfService.governingLawP')}</p>
            </Container>
            <Footer />
        </ Title>
    );
}
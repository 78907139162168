import React, { createContext, useState } from "react";
import { ToastContainer, Toast } from "react-bootstrap";
import Status from "../enums/Status";
import { useTranslation } from "react-i18next";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const { t } = useTranslation();

  /**
   * Show toast message
   * @param {string} message
   * @param {Status} status
   * @returns {void}
   */
  const showToast = (message, status) => {
    const id = new Date().getTime();
    const type = status === Status.Success ? Status.Success : "danger";
    setToasts([...toasts, { id, message, type }]);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer position="top-end" className="p-3 position-fixed">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            onClose={() =>
              setToasts((toasts) => toasts.filter((t) => t.id !== toast.id))
            }
            animation={true}
            show
            delay={3000}
            autohide
            bg={toast.type}
          >
            <Toast.Header className="border-bottom-0">
              <strong className="mr-auto">
                {toast.type === Status.Success ? t("success") : t("fail")}
              </strong>
            </Toast.Header>
            <Toast.Body>{toast.message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export default ToastContext;

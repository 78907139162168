import React from "react";
import { Accordion, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function CommandItem({ command, option, optionGroup, lang }) {
  const { t } = useTranslation();

  const commandType = command?.type;
  const commandName = command?.name_localizations?.[lang] || command.name;
  const commandSubname =
    command?.options?.[optionGroup]?.name_localizations?.[lang] ||
    command?.options?.[optionGroup]?.name ||
    "";

  const commandSubnameGroup =
    command?.options?.[optionGroup]?.options?.[option]?.name_localizations?.[
      lang
    ] ||
    command?.options?.[optionGroup]?.options?.[option]?.name ||
    "";

  const commandDescription =
    command?.options?.[optionGroup]?.options?.[option]
      ?.description_localizations?.[lang] ||
    command?.options?.[optionGroup]?.options?.[option]?.description ||
    command?.options?.[optionGroup]?.description_localizations?.[lang] ||
    command?.options?.[optionGroup]?.description ||
    command?.description_localizations?.[lang] ||
    command?.description;

  const commandOptions =
    optionGroup == null
      ? command?.options
      : option != null
      ? command?.options?.[optionGroup]?.options?.[option]?.options
      : command?.options?.[optionGroup]?.options;

  const commandPermissions = command?.default_member_permissions;
  const displayCommandName = `${commandName} ${commandSubname} ${commandSubnameGroup}`;

  if (commandType === 2 || commandType === 3) {
    return;
  }

  return (
    <Accordion.Item eventKey={displayCommandName} border="secondary">
      <Accordion.Header className="accordion">
        <span className="fw-medium">/{displayCommandName}</span>
        <span className="fw-light d-none d-lg-block">
          &nbsp;-&nbsp;
          {commandDescription.length > 55
            ? `${commandDescription.substring(0, 50)}...`
            : commandDescription}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <Row className="gap-3">
          <Col xs={12}>
            <p className="fw-light text-wrap m-0">{commandDescription}</p>
          </Col>
          {commandOptions?.length > 0 && (
            <Col md={12}>
              <h6>{t("commands.options")}</h6>
              <ul className="m-0">
                {commandOptions.map((option) => (
                  <li key={`option_${option?.name}_${displayCommandName}`}>
                    {(option?.name_localizations?.[lang] || option?.name) +
                      (option?.required ? ` (${t("commands.required")})` : "")}
                  </li>
                ))}
              </ul>
            </Col>
          )}
          <Col md={5}>
            <h6>{t("commands.dmPermission")}</h6>
            <Form.Check
              type="switch"
              id={`dm_${displayCommandName}`}
              checked={command?.dm_permission ?? true}
              disabled
            />
          </Col>
          {commandPermissions != null && (
            <Col md={5}>
              <h6>{t("commands.permission")}</h6>
              <span>{t(`permissions.${commandPermissions}`)}</span>
            </Col>
          )}
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default CommandItem;

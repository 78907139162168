import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import GiveawayItem from "../giveaway/GiveawayItem";
import LoadingSpinner from "../spinners/LoadingSpinner";
import { getGiveaways } from "../../api";

const Giveaways = ({ guildId }) => {
  const [activeGiveaways, setActiveGiveaways] = useState(null);
  const [endedGiveaways, setEndedGiveaways] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const req = await getGiveaways(guildId);
      const tempEndedGiv = [];
      const tempActiveGiv = [];
      for (const giveaway of req) {
        if (giveaway.ended) {
          tempEndedGiv.push(giveaway);
        } else {
          tempActiveGiv.push(giveaway);
        }
      }

      setActiveGiveaways(tempActiveGiv);
      setEndedGiveaways(tempEndedGiv);
    };
    fetchData();
  }, [guildId]);

  if (!activeGiveaways || !endedGiveaways) return <LoadingSpinner />;

  return (
    <>
      {activeGiveaways.length > 0 && (
        <>
          <h3 className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="green"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            &nbsp;{t("dashboard.active")}
          </h3>
          <Accordion defaultActiveKey="0">
            {activeGiveaways.map((giveaway) => (
              <GiveawayItem key={giveaway.giveaway_id} giveaway={giveaway} />
            ))}
          </Accordion>
        </>
      )}

      {endedGiveaways.length > 0 && (
        <>
          <h3 className="mt-3 d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="red"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            &nbsp;{t("dashboard.ended")}
          </h3>
          <Accordion defaultActiveKey="0">
            {endedGiveaways.map((giveaway) => (
              <GiveawayItem key={giveaway.giveaway_id} giveaway={giveaway} />
            ))}
          </Accordion>
        </>
      )}

      {endedGiveaways.length === 0 && activeGiveaways.length === 0 && (
        <h4>{t("dashboard.giveaways.noGiveaways")}</h4>
      )}
    </>
  );
};

export default Giveaways;

import api from "../api";

export async function patchLanguague(language, guildId) {
  const req = await api.patch(`/guilds/${guildId}/language`, {
    language: language,
  });
  return req;
}

export async function getLanguague(guildId) {
  const req = await api.get(`/guilds/${guildId}/language`);
  return req.data;
}

import CommandType from "./CommandType";

const CommandsCategories = {
  [CommandType.Moderation]: [
    "automod",
    "autorole",
    "ban",
    "clear",
    "embed",
    "goodbye",
    "kick",
    "set-language",
    "timeout",
    "set-slowmode",
    "warn",
    "welcome",
    "protection",
  ],
  [CommandType.Giveaway]: [
    "check-giveaway",
    "end-giveaway",
    "giveaway",
    "list-giveaway",
  ],
  [CommandType.LoggingSystem]: ["enabled-logs", "setup-logs"],
  [CommandType.PollSystem]: [
    "check-poll",
    "close-poll",
    "list-poll",
    "poll",
    "time-poll",
  ],
  [CommandType.FourFun]: [
    "avatar",
    "avatar-filter",
    "comment",
    "dice",
    "fight",
    "funny",
    "iq",
    "joger",
    "kerfuś",
    "oogway-says",
    "pokemon",
    "random-animal",
    "song",
    "write",
  ],
  [CommandType.Economy]: [
    "account",
    "add-item",
    "add-money",
    "coin-flip",
    "custom-reply",
    "economy-properties",
    "gamble",
    "give",
    "inventory",
    "item",
    "item-configuration",
    "leaderboard",
    "lottery",
    "remove-item",
    "remove-money",
    "reset",
    "rob",
    "rock-paper-scissors",
    "set-cooldown",
    "set-currency-icon",
    "set-fail-fee-percentage",
    "set-income",
    "set-max-bet",
    "set-success-chance",
    "store",
    "tic-tac-toe",
    "work",
    "quick-click",
    "quiz-duel",
    "roulette",
  ],
};

export default CommandsCategories;

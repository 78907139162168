import React from "react";
import Logo from "../../assets/img/nortBotLogo.webp";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar, Button, Dropdown } from "react-bootstrap";
import useUser from "../../hooks/useUser";
import getGuildIcon from "../../utils/getGuildIcon";
import getUserAvatar from "../../utils/getUserAvatar";
import UserAvatarDropdownMenu from "../user/UserAvatarDropdownMenu";
import { useNavigate } from "react-router-dom";
import { LOGIN_URL } from "../../api";

/**
 *
 * @param {[{}]} guilds
 * @param {*} currentGuildId
 * @returns
 */
const DashboardNavbar = ({ guilds, currentGuildId, toggleSidebar }) => {
  const defaultBgColor = "#212529dF";
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentGuild = guilds.find((guild) => guild.id === currentGuildId);
  return (
    <Navbar
      variant="dark"
      className="pt-2 border-bottom"
      sticky="top"
      style={{ backgroundColor: defaultBgColor, transition: "all 0.3s" }}
    >
      <Container fluid>
        <Navbar.Brand
          onClick={() => navigate("/")}
          className="d-flex align-item-center"
        >
          <img src={Logo} alt="NortBot Logo" height="20px" className="mb-1" />
        </Navbar.Brand>
        <Nav className="me-auto d-sm-flex d-none">
          <Nav.Link onClick={() => navigate("/")}>{t("navbar.home")}</Nav.Link>
          <Nav.Link onClick={() => navigate("/commands")}>
            {t("navbar.commands")}
          </Nav.Link>
        </Nav>
        <Nav className="">
          {loading === false && (user === false || user == null) ? (
            <Button variant="outline-light" href={LOGIN_URL}>
              {t("navbar.login")}
            </Button>
          ) : (
            <>
              <Button
                onClick={toggleSidebar}
                className="text-light d-flex align-items-center"
                variant="dark"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
              </Button>
              <Dropdown className="me-1 d-sm-block d-none">
                <Dropdown.Toggle
                  className="d-flex align-items-center"
                  variant="dark"
                >
                  <span className="me-2 fw-light d-md-block d-none">
                    {currentGuild.name}
                  </span>
                  <img
                    src={getGuildIcon(currentGuild)}
                    alt={currentGuild.name}
                    width="28px"
                    className="rounded-circle"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu align={"end"} className="">
                  {guilds.map((guild) => {
                    if (guild.isNortBot)
                      return (
                        <Dropdown.Item
                          className="d-flex align-items-center"
                          key={guild.id}
                          onClick={() => navigate(`/dashboard/${guild.id}`)}
                        >
                          <img
                            src={getGuildIcon(guild)}
                            alt={guild.name}
                            className="rounded-circle"
                            width="30px"
                          />
                          <span className="ms-2">{guild.name}</span>
                        </Dropdown.Item>
                      );
                    else return null;
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  className="d-flex align-items-center"
                  variant="dark"
                >
                  <span className="me-2 fw-light d-md-block d-none">
                    {user.username}
                  </span>
                  <img
                    src={getUserAvatar(user)}
                    alt={user.username}
                    width="28px"
                    className="rounded-circle"
                  />
                </Dropdown.Toggle>

                <UserAvatarDropdownMenu />
              </Dropdown>
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
};

export default DashboardNavbar;

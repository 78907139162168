import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem, Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../spinners/LoadingSpinner";
import getEmojiId from "../../../utils/getEmojiId";
import UserItem from "../../economyItems/UserItem";
import UserModal from "../../economyItems/UserModal";
import { getCurrency, getUsers } from "../../../api";
const USERS_PER_PAGE = 50;

const Users = ({ guildId }) => {
  const [currencyIcon, setCurrencyIcon] = useState(null);
  const [users, setUsers] = useState(null);
  const [userModal, setUserModal] = useState({ show: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCurrencyIcon = async () => {
      const currencyIcon = await getCurrency(guildId);
      setCurrencyIcon(currencyIcon.currency);
    };

    const fetchUsers = async () => {
      const users = await getUsers(guildId, currentPage - 1, USERS_PER_PAGE);
      setUsers(users.users);
      setTotalPages(users.totalPages);
    };

    fetchCurrencyIcon();
    fetchUsers();
  }, [guildId, currentPage]);

  const handlePageChange = async (pageNumber) => {
    const users = await getUsers(guildId, pageNumber - 1, USERS_PER_PAGE);
    setUsers(users.users);
    setTotalPages(users.totalPages);
    setCurrentPage(pageNumber);
  };

  const showUserModal = (user) => {
    setUserModal(() => ({ user: user, show: true }));
  };

  const closeUserModal = () => {
    setUserModal((prev) => ({ ...prev, show: false }));
  };

  const removeUser = (userId) => {
    setUsers((prev) => prev.filter((user) => user.id !== userId));
  };

  if (!currencyIcon || !users) return <LoadingSpinner />;

  const currencyIconEmojiId = getEmojiId(currencyIcon);
  const sortedUsers = users.sort((a, b) => b.money - a.money);

  return (
    <>
      <ListGroup>
        {sortedUsers.map((user, index) => (
          <UserItem
            user={user}
            currencyIconEmojiId={currencyIconEmojiId}
            currencyIcon={currencyIcon}
            showUserModal={showUserModal}
            key={index}
          />
        ))}
        {sortedUsers.length === 0 && (
          <ListGroupItem>{t("dashboard.economyUsers.noUsers")}</ListGroupItem>
        )}
      </ListGroup>

      <Pagination className="mt-3">
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages)].map((_, pageIndex) => (
          <Pagination.Item
            key={pageIndex + 1}
            active={pageIndex + 1 === currentPage}
            onClick={() => handlePageChange(pageIndex + 1)}
          >
            {pageIndex + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage >= totalPages}
        />
      </Pagination>

      <UserModal
        user={userModal?.user}
        guildId={guildId}
        currencyIconEmojiId={currencyIconEmojiId}
        show={userModal.show}
        hideModal={closeUserModal}
        removeUser={removeUser}
      />
    </>
  );
};

export default Users;

import api from "../api";

export async function patchAutorole(autorole, guildId) {
  const req = await api.patch(`/guilds/${guildId}/autorole`, {
    autorole: autorole,
  });
  return req;
}

export async function getAutorole(guildId) {
  const req = await api.get(`/guilds/${guildId}/autorole`);
  return req.data;
}

import React, { useState, useEffect } from "react";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../spinners/LoadingSpinner";
import getEmojiId from "../../../utils/getEmojiId";
import StoreItem from "../../economyItems/StoreItem";
import StoreItemModal from "../../economyItems/StoreModal";
import { getCurrency, getItems, getRoles } from "../../../api";

const Store = ({ guildId }) => {
  const [currencyIcon, setCurrencyIcon] = useState(null);
  const [items, setItems] = useState(null);
  const [itemModal, setItemModal] = useState({
    show: false,
    item: null,
    isNew: false,
  });
  const [roles, setRoles] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCurrencyIcon = async () => {
      const currencyIcon = await getCurrency(guildId);
      setCurrencyIcon(currencyIcon.currency);
    };

    const fetchItems = async () => {
      const items = await getItems(guildId);
      setItems(items.items);
    };

    const fetchRoles = async () => {
      const roles = await getRoles(guildId);
      setRoles(roles);
    };

    fetchCurrencyIcon();
    fetchItems();
    fetchRoles();
  }, [guildId]);

  const showItemModal = (item) => {
    setItemModal(() => ({ item: item, show: true, isNew: !item }));
  };

  const closeItemModal = () => {
    setItemModal((prev) => ({ ...prev, show: false }));
  };

  const removeItem = (itemId) => {
    setItems((prev) => prev.filter((item) => item.id !== itemId));
  };

  const updateItem = (updatedItem) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item
      )
    );
  };

  const addItem = (item) => {
    setItems((prev) => [...prev, item]);
  };

  if (!currencyIcon || !items || !roles) return <LoadingSpinner />;

  const currencyIconEmoji = getEmojiId(currencyIcon);
  const sortedItems = items.sort((a, b) => b.cost - a.cost);

  return (
    <>
      <div className="my-3 d-flex justify-content-start">
        <Button key="create-item-button" onClick={() => showItemModal()}>
          {t("dashboard.economyStore.create")}
        </Button>
      </div>
      <ListGroup>
        {sortedItems.map((item, index) => (
          <StoreItem
            item={item}
            key={index}
            currencyIconEmojiId={currencyIconEmoji}
            showItemModal={showItemModal}
          />
        ))}
        {sortedItems.length === 0 && (
          <ListGroupItem>{t("dashboard.economyStore.noItems")}</ListGroupItem>
        )}
      </ListGroup>

      <StoreItemModal
        economyItem={itemModal?.item}
        guildId={guildId}
        currencyIconEmojiId={currencyIconEmoji}
        show={itemModal.show}
        hideModal={closeItemModal}
        removeItem={removeItem}
        updateItem={updateItem}
        addItem={addItem}
        isNew={itemModal.isNew}
        roles={roles}
      />
    </>
  );
};

export default Store;

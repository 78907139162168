import React from "react";
import { Row, Col, ListGroup, Badge } from "react-bootstrap";
import truncateText from "../../utils/truncateText";
import getStatusColor from "../../utils/getStatusColor";
import highlightKeyword from "../../utils/highlightKeyword";
import { useTranslation } from "react-i18next";

const ReplyItem = ({ reply, showReplyModal }) => {
  const { t } = useTranslation();
  const truncateReplyContent = truncateText(reply.content, 80);

  return (
    <ListGroup.Item
      action
      onClick={() => showReplyModal(reply)}
      key={`reply-${reply.id}`}
      variant="dark"
    >
      <Row>
        <Col
          md={8}
          xs="auto"
          className="d-flex align-items-center justify-content-start"
        >
          <span>
            {highlightKeyword(truncateReplyContent, "<money>", "Money")}
          </span>
        </Col>
        <Col
          md={4}
          xs="auto"
          className="d-flex align-items-center justify-content-end my-1"
        >
          <Badge pill bg={getStatusColor(reply.status)}>
            <span className="fw-bold">
              {t(`status.${reply.status.toLowerCase()}`).toLocaleUpperCase()}
            </span>
          </Badge>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default ReplyItem;

import React from "react";
import { Badge } from "react-bootstrap";

const highlightKeyword = (text, keyword, replace) => {
  const parts = text.split(keyword);
  return parts.map((part, index) =>
    index !== parts.length - 1 ? (
      <React.Fragment key={index}>
        {part}
        <Badge>{replace}</Badge>
      </React.Fragment>
    ) : (
      part
    )
  );
};

export default highlightKeyword;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Accordion, Container } from "react-bootstrap";
import { calculateTimeLeft, formatTime } from "../../utils/calculateTimeLeft";

const GiveawayItem = ({ giveaway }) => {
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(giveaway.end_time)
  );
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(giveaway.end_time));
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Accordion.Item eventKey={giveaway.giveaway_id}>
      <Accordion.Header>
        <Container fluid className="p-0 m-0">
          {giveaway.prize}
        </Container>
      </Accordion.Header>
      <Accordion.Body
        className="giveawayThumbnail"
        style={{
          backgroundImage:
            giveaway?.thumbnail_url && `url(${giveaway.thumbnail_url})`,
        }}
      >
        <Row className={giveaway?.thumbnail_url != null && "blur rounded"}>
          <Col md={12} className="my-2">
            <h4>{t("dashboard.giveaways.prize")}</h4>
            <span>{giveaway.prize}</span>
          </Col>
          {giveaway.description && (
            <Col sm={12} className="my-2">
              <h5>{t("dashboard.giveaways.description")}</h5>
              <span>{giveaway.description}</span>
            </Col>
          )}
          <Col sm={4} className="my-2">
            <h5>{t("dashboard.giveaways.participants")}</h5>
            <span>{giveaway.giveaway_participants.length}</span>
          </Col>
          <Col sm={4} className="my-2">
            <h5>{t("dashboard.giveaways.winnersCount")}</h5>
            <span>{giveaway.winners_count}</span>
          </Col>
          {giveaway.ended === true ? (
            <Col sm={4} className="my-2">
              <h5>{t("dashboard.giveaways.endTime")}</h5>
              <span>{new Date(giveaway.end_time * 1000).toLocaleString()}</span>
            </Col>
          ) : (
            <Col sm={4} className="my-2">
              <h5>{t("dashboard.endsIn")}</h5>
              <span>{formatTime(timeLeft)}</span>
            </Col>
          )}
          <Col sm={12} className="my-2">
            <h5>
              <a
                href={`https://discord.com/channels/${giveaway.guild_id}/${giveaway.channel_id}/${giveaway.message_id}`}
                target="_blank"
                rel="noreferrer"
              >
                {t("dashboard.giveaways.goToTheGiveaway")}
              </a>
            </h5>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default GiveawayItem;

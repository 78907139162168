import React, { useState, useEffect } from "react";
import {
  ListGroup,
  Button,
  ButtonToolbar,
  InputGroup,
  Form,
  ButtonGroup,
  ListGroupItem,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../spinners/LoadingSpinner";
import ReplyItem from "../../economyItems/ReplyItem";
import ReplyModal from "../../economyItems/ReplyModal";
import Status from "../../../enums/Status";
import { getReplies } from "../../../api";

const Replies = ({ guildId }) => {
  const [replies, setReplies] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [replyModal, setReplyModal] = useState({
    show: false,
    reply: null,
    isNew: false,
  });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchReplies = async () => {
      const replies = await getReplies(guildId);
      setReplies(replies.replies);
    };

    fetchReplies();
  }, [guildId]);

  const showReplyModal = (reply) => {
    setReplyModal(() => ({ reply: reply, show: true, isNew: !reply }));
  };

  const closeReplyModal = () => {
    setReplyModal((prev) => ({ ...prev, show: false }));
  };

  const removeReply = (replyId) => {
    setReplies((prev) => prev.filter((reply) => reply.id !== replyId));
  };

  const updateReply = (updatedReply) => {
    setReplies((prevReplies) =>
      prevReplies.map((reply) =>
        reply.id === updatedReply.id ? { ...reply, ...updatedReply } : reply
      )
    );
  };

  const addReply = (reply) => {
    setReplies((prev) => [...prev, reply]);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setSelectedStatus(value);
  };

  if (!replies) return <LoadingSpinner />;

  const filteredReplies =
    selectedStatus !== "ALL"
      ? replies.filter(
          (reply) => reply.status.toUpperCase() === selectedStatus.toUpperCase()
        )
      : replies.sort((a, b) => {
          if (
            a.status === Status.Success.toUpperCase() &&
            b.status !== Status.Success.toUpperCase()
          ) {
            return -1;
          }
          if (
            a.status !== Status.Success.toUpperCase() &&
            b.status === Status.Success.toUpperCase()
          ) {
            return 1;
          }

          return a.content.localeCompare(b.content);
        });

  return (
    <>
      <ButtonToolbar className="my-3 justify-content-between">
        <ButtonGroup className="my-2">
          <Button key="create-item-button" onClick={() => showReplyModal()}>
            {t("dashboard.economyReplies.create")}
          </Button>
        </ButtonGroup>
        <InputGroup className="my-2">
          <Form.Select
            id="reply-status-sort-select"
            onChange={handleStatusChange}
          >
            <option value={"ALL"}>{t("all")}</option>
            <option value={Status.Success.toLocaleUpperCase()}>
              {t("status.success")}
            </option>
            <option value={Status.Fail.toLocaleUpperCase()}>
              {t("status.fail")}
            </option>
          </Form.Select>
        </InputGroup>
      </ButtonToolbar>
      <ListGroup>
        {filteredReplies.map((reply, index) => (
          <ReplyItem
            reply={reply}
            showReplyModal={showReplyModal}
            key={index}
          />
        ))}
        {filteredReplies.length === 0 && (
          <ListGroupItem>
            {t("dashboard.economyReplies.noReplies")}
          </ListGroupItem>
        )}
      </ListGroup>

      <ReplyModal
        reply={replyModal.reply}
        guildId={guildId}
        show={replyModal.show}
        hideModal={closeReplyModal}
        removeReply={removeReply}
        updateReply={updateReply}
        addReply={addReply}
        isNew={replyModal.isNew}
      />
    </>
  );
};

export default Replies;

import api from "../api";

export async function patchGoodbye(goodbyeParam, guildId) {
  const req = await api.patch(`/guilds/${guildId}/goodbye`, {
    goodbye: goodbyeParam,
  });
  return req.data;
}

export async function getGoodbye(guildId) {
  const req = await api.get(`/guilds/${guildId}/goodbye`);
  return req.data;
}

export async function deleteGoodbye(guildId) {
  const req = await api.delete(`/guilds/${guildId}/goodbye`);
  return req.data;
}

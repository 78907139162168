import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PollChart from "../poll/PollChart";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import PollItem from "../poll/PollItem";
import LoadingSpinner from "../spinners/LoadingSpinner";
import { getPolls } from "../../api";

Chart.register(CategoryScale);

const Polls = ({ guildId }) => {
  const [activePolls, setActivePolls] = useState([]);
  const [endedPolls, setEndedPolls] = useState([]);
  const [polls, setPolls] = useState(null);
  const { t } = useTranslation();
  const [chartData, setChartData] = useState(null);
  const [chartParams, setChartParams] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const req = await getPolls(guildId);
      const tempEnded = [];
      const tempActive = [];
      for (const poll of req) {
        if (poll.ended) {
          tempEnded.push(poll);
        } else {
          tempActive.push(poll);
        }
      }

      setPolls(req);
      setActivePolls(tempActive);
      setEndedPolls(tempEnded);
    };
    fetchData();
  }, [guildId]);

  const setChart = (pollId) => {
    const poll = polls.find((poll) => poll.poll_id === pollId);
    const choices = JSON.parse(poll.choices);
    if (choices.length === 0) {
      choices.push(t("dashboard.polls.yes"));
      choices.push(t("dashboard.polls.no"));
    }
    const votes = [];
    for (let i = 0; i < choices.length; i++) {
      votes.push(0);
    }

    for (const vote of poll.votes) {
      const voteArr = JSON.parse(vote.votes) ?? [];
      voteArr.forEach((vote) => {
        votes[vote]++;
      });
    }

    setChartData({
      labels: choices,
      datasets: [
        {
          label: choices,
          data: votes,
          hoverOffset: 4,
        },
      ],
    });
    setChartParams({
      title: poll.question,
      membersCount: poll.votes.length,
      messageUrl: `https://discord.com/channels/${poll.guild_id}/${poll.channel_id}/${poll.message_id}`,
      maxChoices: poll.max_choices,
      isAnonymous: poll.is_anonymous,
      allowedRole: poll.allowed_role,
      endTime: poll.end_time,
      ended: poll.ended,
      id: poll.poll_id,
    });
  };

  if (!polls) return <LoadingSpinner />;

  return (
    <>
      {chartData && (
        <PollChart chartData={chartData} chartParams={chartParams} />
      )}
      {activePolls.length > 0 && (
        <>
          <h3 className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="green"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            &nbsp;{t("dashboard.active")}
          </h3>
          <div className="d-flex flex-row flex-wrap gap-2">
            {activePolls.map((poll) => (
              <PollItem key={poll.poll_id} poll={poll} setChart={setChart} />
            ))}
          </div>
        </>
      )}

      {endedPolls.length > 0 && (
        <>
          <h3 className="mt-3 d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="red"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            &nbsp;{t("dashboard.ended")}
          </h3>
          <div className="d-flex flex-row flex-wrap gap-2">
            {endedPolls.map((poll) => (
              <PollItem key={poll.poll_id} poll={poll} setChart={setChart} />
            ))}
          </div>
        </>
      )}

      {endedPolls.length === 0 && activePolls.length === 0 && (
        <h4>{t("dashboard.polls.noPolls")}</h4>
      )}
    </>
  );
};

export default Polls;

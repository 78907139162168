/**
 *
 * @param {string} emoji Discord Emoji String
 * @returns
 */
const getEmojiId = (emoji) => {
  const discordEmojiRegex = /^<:(\w+):(\d+)>$/;
  const match = emoji?.match(discordEmojiRegex);

  if (match) {
    return match[2];
  }

  return emoji;
};

export default getEmojiId;

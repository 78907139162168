import { useContext } from "react";
import UserContext from "../context/UserContext.js";

const useUser = () => {
  /**
   * @type {{
   *  user: User,
   *  loading: boolean
   *  logout: () => void;
   * }}
   */
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

export default useUser;

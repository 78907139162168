import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../spinners/LoadingSpinner";
import getEmojiId from "../../../utils/getEmojiId";
import {
  getProperties,
  putCooldowns,
  putFailFeePercentages,
  putIncomes,
  putMaxBets,
  putSuccessChances,
} from "../../../api";
import useToast from "../../../hooks/useToast";
import Status from "../../../enums/Status";
const discordEmojiUrl = "https://cdn.discordapp.com/emojis/";

const Properties = ({ guildId, guild }) => {
  const [economyProperties, setEconomyProperties] = useState(null);
  const { t } = useTranslation();
  const { showToast } = useToast();

  useEffect(() => {
    const fetchLanguage = async () => {
      const economyProperties = await getProperties(guildId);
      setEconomyProperties(economyProperties);
    };

    fetchLanguage();
  }, [guildId]);

  const handleUpdateCooldowns = (field, value) => {
    setEconomyProperties((prev) => ({
      ...prev,
      cooldowns: {
        ...prev.cooldowns,
        [field]: value,
      },
    }));
  };

  const handleUpdateIncomes = (category, field, value) => {
    setEconomyProperties((prev) => ({
      ...prev,
      incomes: {
        ...prev.incomes,
        [category]: {
          ...prev.incomes[category],
          [field]: value,
        },
      },
    }));
  };

  const handleUpdateMaxBets = (field, value) => {
    setEconomyProperties((prev) => ({
      ...prev,
      maxBets: {
        ...prev.maxBets,
        [field]: value,
      },
    }));
  };

  const handleUpdateSuccessChances = (field, value) => {
    setEconomyProperties((prev) => ({
      ...prev,
      successChances: {
        ...prev.successChances,
        [field]: value,
      },
    }));
  };

  const handleUpdateFailFeePercentages = (field, value) => {
    setEconomyProperties((prev) => ({
      ...prev,
      failFeePercentages: {
        ...prev.failFeePercentages,
        [field]: value,
      },
    }));
  };

  const updateEconomyCooldowns = async () => {
    try {
      const data = await putCooldowns(economyProperties.cooldowns, guildId);
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
      } else {
        showToast("Error saving cooldowns", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg || "Error saving cooldowns",
        Status.Fail
      );
    }
  };

  const updateEconomyIncomes = async () => {
    try {
      const data = await putIncomes(economyProperties.incomes, guildId);
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
      } else {
        showToast("Error saving incomes", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg || "Error saving incomes",
        Status.Fail
      );
    }
  };

  const updateEconomySuccessChances = async () => {
    try {
      const data = await putSuccessChances(
        economyProperties.successChances,
        guildId
      );
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
      } else {
        showToast("Error saving success chances", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg || "Error saving success chances",
        Status.Fail
      );
    }
  };

  const updateEconomyMaxBets = async () => {
    try {
      const data = await putMaxBets(economyProperties.maxBets, guildId);
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
      } else {
        showToast("Error saving max bets", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg || "Error saving max bets",
        Status.Fail
      );
    }
  };

  const updateEconomyFailFeePercentages = async () => {
    try {
      const data = await putFailFeePercentages(
        economyProperties.failFeePercentages,
        guildId
      );
      if (data.status === Status.Success) {
        showToast(data.message, Status.Success);
      } else {
        showToast("Error saving fail fee percentages", Status.Fail);
      }
    } catch (e) {
      showToast(
        e?.response?.data?.errors?.[0]?.msg ||
          "Error saving fail fee percentages",
        Status.Fail
      );
    }
  };

  if (!economyProperties) return <LoadingSpinner />;

  const currencyIcon = economyProperties.currency;
  const currencyIconEmojiId = getEmojiId(currencyIcon);

  return (
    <>
      <Row>
        <Col md="auto">
          <Card className="m-2 my-3 bg-dark-subtle" id="currency">
            <Card.Header className="bg-success">
              <Card.Title className="m-0">
                {t("dashboard.economyProperties.currency", {
                  guildName: guild.name,
                })}
              </Card.Title>
            </Card.Header>
            <Card.Body className="d-flex align-items-center justify-content-center">
              {!isNaN(currencyIconEmojiId) ? (
                <img
                  src={`${discordEmojiUrl}${currencyIconEmojiId}.webp`}
                  alt="Currency Icon"
                />
              ) : (
                <h4 className="m-0">{currencyIconEmojiId}</h4>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col md={12}>
          <Card className="m-2 my-3 bg-dark-subtle" id="cooldowns">
            <Card.Header className="bg-primary">
              <Card.Title className="m-0">
                {t("dashboard.economyProperties.cooldowns")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="auto">
                  <Form.Group className="mb-3" controlId="cooldowns.Work">
                    <Form.Label>
                      {t("dashboard.economyProperties.work")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="1m"
                      maxLength="3"
                      value={economyProperties.cooldowns.work}
                      onChange={(e) =>
                        handleUpdateCooldowns("work", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md="auto">
                  <Form.Group className="mb-3" controlId="cooldowns.Gamble">
                    <Form.Label>
                      {t("dashboard.economyProperties.gamble")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="2m"
                      maxLength="3"
                      value={economyProperties.cooldowns.gamble}
                      onChange={(e) =>
                        handleUpdateCooldowns("gamble", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md="auto">
                  <Form.Group className="mb-3" controlId="cooldowns.Rob">
                    <Form.Label>
                      {t("dashboard.economyProperties.rob")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="10h"
                      maxLength="3"
                      value={economyProperties.cooldowns.rob}
                      onChange={(e) =>
                        handleUpdateCooldowns("rob", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md="auto">
                  <Form.Group className="mb-3" controlId="cooldowns.Lottery">
                    <Form.Label>
                      {t("dashboard.economyProperties.lottery")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="80s"
                      maxLength="3"
                      value={economyProperties.cooldowns.lottery}
                      onChange={(e) =>
                        handleUpdateCooldowns("lottery", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md="auto">
                  <Form.Group className="mb-3" controlId="cooldowns.CoinFlip">
                    <Form.Label>
                      {t("dashboard.economyProperties.coinFlip")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="50s"
                      maxLength="3"
                      value={economyProperties.cooldowns.coinFlip}
                      onChange={(e) =>
                        handleUpdateCooldowns("coinFlip", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md="auto">
                  <Form.Group
                    className="mb-3"
                    controlId="cooldowns.RockPaperScissorsSingleplayer"
                  >
                    <Form.Label>
                      {t(
                        "dashboard.economyProperties.rockPaperScissorsSingleplayer"
                      )}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="50s"
                      maxLength="3"
                      value={
                        economyProperties.cooldowns
                          .rockPaperScissorsSingleplayer
                      }
                      onChange={(e) =>
                        handleUpdateCooldowns(
                          "rockPaperScissorsSingleplayer",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Button onClick={updateEconomyCooldowns}>
                    {t("dashboard.save")}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={12}>
          <Card className="m-2 my-3 bg-dark-subtle" id="incomes">
            <Card.Header className="bg-success">
              <Card.Title className="m-0">
                {t("dashboard.economyProperties.incomes")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row className="g-4">
                <Col md={6} lg={4}>
                  <Card>
                    <Card.Header>
                      <strong>{t("dashboard.economyProperties.work")}</strong>
                    </Card.Header>
                    <Card.Body>
                      <Col md="auto">
                        <Form.Group className="mb-3" controlId="incomeWork.Min">
                          <Form.Label>
                            {t("dashboard.economyProperties.min")}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="100"
                            max={999999}
                            min={1}
                            value={economyProperties.incomes.work.min}
                            onChange={(e) =>
                              handleUpdateIncomes("work", "min", e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md="auto">
                        <Form.Group className="mb-3" controlId="incomeWork.Max">
                          <Form.Label>
                            {t("dashboard.economyProperties.max")}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="300"
                            max={999999}
                            min={1}
                            value={economyProperties.incomes.work.max}
                            onChange={(e) =>
                              handleUpdateIncomes("work", "max", e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={4}>
                  <Card>
                    <Card.Header>
                      <strong>{t("dashboard.economyProperties.gamble")}</strong>
                    </Card.Header>
                    <Card.Body>
                      <Col md="auto">
                        <Form.Group
                          className="mb-3"
                          controlId="incomeGamble.Min"
                        >
                          <Form.Label>
                            {t("dashboard.economyProperties.min")}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="500"
                            max={999999}
                            min={1}
                            value={economyProperties.incomes.gamble.min}
                            onChange={(e) =>
                              handleUpdateIncomes(
                                "gamble",
                                "min",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md="auto">
                        <Form.Group
                          className="mb-3"
                          controlId="incomeGamble.Max"
                        >
                          <Form.Label>
                            {t("dashboard.economyProperties.max")}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="800"
                            max={999999}
                            min={1}
                            value={economyProperties.incomes.gamble.max}
                            onChange={(e) =>
                              handleUpdateIncomes(
                                "gamble",
                                "max",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={4}>
                  <Card>
                    <Card.Header>
                      <strong>{t("dashboard.economyProperties.rob")}</strong>
                    </Card.Header>
                    <Card.Body>
                      <Col md="auto">
                        <Form.Group className="mb-3" controlId="incomeRob.Max">
                          <Form.Label>
                            {t("dashboard.economyProperties.max")}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="50,000"
                            max={999999}
                            min={1}
                            value={economyProperties.incomes.rob.max}
                            onChange={(e) =>
                              handleUpdateIncomes("rob", "max", e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12}>
                  <Button onClick={updateEconomyIncomes}>
                    {t("dashboard.save")}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={12}>
          <Card className="m-2 my-3 bg-dark-subtle" id="max-bets">
            <Card.Header className="bg-primary">
              <Card.Title className="m-0">
                {t("dashboard.economyProperties.maxBets")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={6} lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="maxBets.SingleplayerGames"
                  >
                    <Form.Label>
                      {t("dashboard.economyProperties.singleplayerGames")}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="100"
                      min={1}
                      max={999999}
                      value={economyProperties.maxBets.singleplayerGames}
                      onChange={(e) =>
                        handleUpdateMaxBets("singleplayerGames", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6} lg={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="maxBets.MultiplayerGames"
                  >
                    <Form.Label>
                      {t("dashboard.economyProperties.multiplayerGames")}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      disabled
                      placeholder={t("dashboard.economyProperties.unlimited")}
                      min={1}
                      max={999999}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} lg={4}>
                  <Form.Group className="mb-3" controlId="maxBets.Lottery">
                    <Form.Label>
                      {t("dashboard.economyProperties.lottery")}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="200"
                      min={1}
                      max={999999}
                      value={economyProperties.maxBets.lottery}
                      onChange={(e) =>
                        handleUpdateMaxBets("lottery", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Button onClick={updateEconomyMaxBets}>
                    {t("dashboard.save")}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={12} lg={8}>
          <Card className="m-2 my-3 bg-dark-subtle" id="success-chances">
            <Card.Header className="bg-success">
              <Card.Title className="m-0">
                {t("dashboard.economyProperties.successChances")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="successChances.Gamble"
                  >
                    <Form.Label>
                      {`${t("dashboard.economyProperties.gamble")} ${Math.round(
                        economyProperties.successChances.gamble * 100
                      )}%`}
                    </Form.Label>
                    <Form.Range
                      type="range"
                      placeholder="100"
                      min={0}
                      max={100}
                      step={1}
                      value={economyProperties.successChances.gamble * 100}
                      onChange={(e) =>
                        handleUpdateSuccessChances(
                          "gamble",
                          e.target.value / 100
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="successChances.Rob">
                    <Form.Label>
                      {`${t("dashboard.economyProperties.rob")} ${Math.round(
                        economyProperties.successChances.rob * 100
                      )}%`}
                    </Form.Label>
                    <Form.Range
                      type="range"
                      placeholder="100"
                      min={0}
                      max={100}
                      step={1}
                      value={economyProperties.successChances.rob * 100}
                      onChange={(e) =>
                        handleUpdateSuccessChances("rob", e.target.value / 100)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Button onClick={updateEconomySuccessChances}>
                    {t("dashboard.save")}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={12} lg={4}>
          <Card className="m-2 my-3 bg-dark-subtle" id="fail-fee-percentages">
            <Card.Header className="bg-danger">
              <Card.Title className="m-0">
                {t("dashboard.economyProperties.failFeePercentages")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="failFeePercentages.Gamble"
                  >
                    <Form.Label>
                      {`${t("dashboard.economyProperties.gamble")} ${Math.round(
                        economyProperties.failFeePercentages.gamble
                      )}%`}
                    </Form.Label>
                    <Form.Range
                      type="range"
                      placeholder="100"
                      min={0}
                      max={200}
                      step={1}
                      value={economyProperties.failFeePercentages.gamble}
                      onChange={(e) =>
                        handleUpdateFailFeePercentages("gamble", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Button onClick={updateEconomyFailFeePercentages}>
                    {t("dashboard.save")}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Properties;

import React from "react";
import { Container, Button } from "react-bootstrap";
import WebsiteNavbar from "../components/navbar/Navbar";
import Footer from "../components/basic/Footer";
import Title from "../components/basic/Title";
import { LOGIN_URL } from "../api";

export default function AuthorizationError() {
  return (
    <Title title="Not Found">
      <WebsiteNavbar />
      <Container className="d-flex align-items-center justify-content-center vh-75">
        <div className="d-block">
          <h1>Authorization Error</h1>
          <Button href="/" className="me-1">
            Go back
          </Button>
          <Button href={LOGIN_URL}>Login</Button>
        </div>
      </Container>
      <Footer />
    </Title>
  );
}

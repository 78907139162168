import React from "react";
import { Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import truncateText from "../../utils/truncateText";

function PollItem({ poll, setChart }) {
  const { t } = useTranslation();

  const scrollUp = () => {
    const dashboardContent = document.getElementById("dashboardContent");
    if (dashboardContent) {
      dashboardContent.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Card
      as={Button}
      key={poll.poll_id}
      onClick={() => {
        setChart(poll.poll_id);
        scrollUp();
      }}
      className="p-0"
      style={{ width: window.innerWidth > 548 ? "15rem" : "100%" }}
    >
      <Card.Header className="border-0 w-100 p-0">
        <Card.Title
          className="m-0 fs-5 p-2 d-flex justify-content-center align-items-center"
        >
          {truncateText(poll.question, 30)}
        </Card.Title>
      </Card.Header>
      <Card.Body className="m-0 p-2">
        {`${poll.votes.length} ${t("dashboard.polls.membersVoted")}`}
      </Card.Body>
    </Card>
  );
}
export default PollItem;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/nortBotLogo.webp";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar, Button, Dropdown } from "react-bootstrap";
import useUser from "../../hooks/useUser";
import getUserAvatar from "../../utils/getUserAvatar";
import UserAvatarDropdownMenu from "../user/UserAvatarDropdownMenu";
import { LOGIN_URL } from "../../api";

const WebsiteNavbar = () => {
  const defaultBgColor = "#212529";
  const [navbarBg, setNavbarBg] = useState(defaultBgColor);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 30) {
      setNavbarBg("#212529dF");
    } else {
      setNavbarBg(defaultBgColor);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Navbar
      expand="sm"
      sticky="top"
      variant="dark"
      className="pt-2"
      style={{
        backgroundColor: isMenuOpen ? defaultBgColor : navbarBg,
        transition: "all 0.3s",
      }}
    >
      <Container>
        <Navbar.Brand
          onClick={() => navigate("/")}
          className="d-flex justify-content-center align-item-center"
        >
          <img src={Logo} alt="NortBot Logo" height="20px" className="mb-1" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleMenuToggle}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => navigate("/")}>
              {t("navbar.home")}
            </Nav.Link>
            <Nav.Link onClick={() => navigate("/commands")}>
              {t("navbar.commands")}
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/dashboard")}
              className={!user && "d-none"}
            >
              {t("navbar.dashboard")}
            </Nav.Link>
          </Nav>
          {loading ||
            (!user ? (
              <Button variant="outline-light" href={LOGIN_URL}>
                {t("navbar.login")}
              </Button>
            ) : (
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  as="img"
                  id="dropdown-basic"
                  src={getUserAvatar(user)}
                  width="40px"
                  className="rounded-circle"
                ></Dropdown.Toggle>

                <UserAvatarDropdownMenu />
              </Dropdown>
            ))}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default WebsiteNavbar;

import React, { createContext, useState } from "react";
import ConfirmationModal from "../components/modals/ConfirmationModal.js";

const ConfirmationModalContext = createContext();

export const ConfirmationModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({
    show: false,
    onConfirm: () => {},
  });

  const showModal = (onConfirm) => {
    setModalProps({
      show: true,
      onConfirm,
    });
  };

  const hideModal = () => {
    setModalProps({
      ...modalProps,
      show: false,
    });
  };

  return (
    <ConfirmationModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <ConfirmationModal
        show={modalProps.show}
        onHide={hideModal}
        onConfirm={() => {
          modalProps.onConfirm();
          hideModal();
        }}
      />
    </ConfirmationModalContext.Provider>
  );
};

export default ConfirmationModalContext;

import React from "react";
import { Spinner, Container } from "react-bootstrap";

const LoadingSpinner = () => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center p-5 my-5"
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  );
};

export default LoadingSpinner;

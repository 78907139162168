import api from "../api";

export async function getLogs(guildId) {
  const req = await api.get(`/guilds/${guildId}/logs`);
  return req.data;
}

export async function patchLogs(logs, guildId) {
  const req = await api.patch(`/guilds/${guildId}/logs`, {
    logs: logs,
  });
  return req.data;
}

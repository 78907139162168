import React, { useEffect } from 'react';

const Title = ({ title, children }) => {
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    document.title = `${title} | NortBot`;

    const OgTitle = document.querySelector('meta[property="og:title"]');
    OgTitle?.setAttribute('content', `${title} | NortBot`);

    const TwitterTitle = document.querySelector('meta[property="twitter:title"]');
    TwitterTitle?.setAttribute('content', `${title} | NortBot`);
  }, [title]);

  return <div>{children}</div>;
};

export default Title;
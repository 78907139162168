import React from "react";
import { Container, Card, Button, Col, Row } from "react-bootstrap";
import WebsiteNavbar from "../components/navbar/Navbar";
import Footer from "../components/basic/Footer";
import Title from "../components/basic/Title";
import useUser from "../hooks/useUser";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import getGuildIcon from "../utils/getGuildIcon";
import getGuildBanner from "../utils/getGuildBanner";
import { AUTH_URL } from "../api";

function Dashboard() {
  const { loading, user } = useUser();
  const { t } = useTranslation();
  const CLIENT_ID = "931645208834228224"; //1034159317420621965

  const navigate = useNavigate();
  if (loading) return;

  if (!user) {
    return navigate("/");
  }

  return (
    <Title title={t("navbar.dashboard")}>
      <WebsiteNavbar />
      <Container className="mt-2">
        <div className="text-center">
          <h1>{t("dashboard.yourServers")}</h1>
          <p>{t("dashboard.chooseServer")}</p>
        </div>
        <Row className="d-flex flex-wrap justify-content-center puffAnimation gap-3">
          {user.guilds.map((guild) => (
            <Col
              xl={3}
              md={5}
              xs={12}
              key={guild.id}
              className="d-flex justify-content-center m-0 p-0"
            >
              <Card className="p-2 w-100 mx-2" style={{ width: "25rem" }}>
                <Card.Img
                  variant="top"
                  height="85px"
                  className="rounded"
                  src={getGuildBanner(guild, 2048)}
                  style={{ objectFit: "cover" }}
                />
                <img
                  height="64px"
                  width="64px"
                  alt={guild.name}
                  src={getGuildIcon(guild, 1024)}
                  className="rounded-circle"
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "20px",
                    boxShadow: "0 0 0 2px",
                  }}
                ></img>
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{guild.name}</Card.Title>
                  <Card.Text className="fw-light">
                    {guild.owner === true
                      ? t("dashboard.owner")
                      : t("dashboard.admin")}
                  </Card.Text>

                  <div className="mt-auto">
                    {guild.isNortBot ? (
                      <Button
                        variant="primary"
                        className="w-100"
                        onClick={() => navigate(`/dashboard/${guild.id}`)}
                      >
                        {t("dashboard.configurate")}
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        className="w-100"
                        href={`https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}&permissions=8&scope=bot+applications.commands&guild_id=${guild.id}&redirect_uri=${AUTH_URL}/guilds&response_type=code`}
                      >
                        {t("dashboard.addToServer")}
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </Title>
  );
}

export default Dashboard;
